import React from "react";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import GetParams from "../../utils/getParams";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter } from "../../replace_str_constant";
import { Box } from "@chakra-ui/react";

const DepositIframe = () => {
  const depositLink = useSelector((state) => state.isDepositLink);

  const method = GetParams("method");
  return (
    <Box
      position={"fixed"}
      w="100%"
      h="100%"
      top="0px"
      left="0px"
      zIndex={"9999999"}
    >
      <CloseDetailPage
        className={`bg-base-white dark:bg-base-dark !mb-0`}
        title={capitalizeFirstLetter(method)}
        hasPrev={<Box w="20px"></Box>}
      />
      <iframe
        src={depositLink}
        className="h-[calc(100vh-60px)] w-full bg-second-color"
      ></iframe>
    </Box>
  );
};

export default DepositIframe;
