import { combineReducers } from "redux";
import LoadingReducers from "./LoadingReducers";
import NoticeReducers from "./NoticeReducers";
import DetailPageReducers from "./DetailPage";
import MenuReducers from "./OpenMenu";
import MenuSelectReducers from "./MenuStatus";
import MemberInfoReducers from "./member/memberInfo";
import GameListReducers from "./game/gameList";
import GameLinkReducers from "./game/gameLink";
import LoadingStatusReducers from "./loading/LoadingStatus";
import DepositLinkReducers from "./cashflow/depositLink";
import OpenGameReducers from "./game/openGame";
import cryptoReducers from "./member/cryptoRate";
import ReferDetailReducers from "./member/referDetail";
import FullScreenReducers from "./game/fullScreen";
import AllGameReducers from "./game/allGame";
import languagePopReducers from "./member/languagePop";
import comissionListPopReducers from "./member/comissionListPop";
import GameVipBarReducers from "./game/gameVipBar";
import GameAccessToolReducers from "./game/gameAccessTool";
import StoreFileReducers from "./member/storeFile";
import GameConfigReducers from "./game/gameConfig";
import StoreTransferWalletReducers from "./member/transferWallet";
import mainThemeReducers from "./member/theme";
import FavoriteGameListReducers from "./game/favoriteGameList";
import storeVipDetailReducers from "./member/vipDetail";
import MenuStickyLeftReducers from "./menuStickyLeft";
import gameDescriptionPopReducers from "./member/openGameDescription";
import StoreInboxReducers from "./member/storeInboxDetail";
import openChatRoomReducers from "./member/openChat";
import TriggerGameBoxTransferReducers from "./game/triggerTransfer";
import TriggerReducers from "./trigger";
import marqueeListReducers from "./member/marqueeList";
import openClearCacheReducers from "./member/clearCache";
import triggerGameReducers from "./game/triggerGameLink";
import inboxListReducers from "./member/inboxList";
import gameLoadingReducers from "./game/triggerGameLoading";
import CurrencyReducers from "./member/currency";
import walletSettingPopReducers from "./member/walletSettingPop";
import HideZeroAmountReducers from "./member/hideZeroAmount";
import DisplayCryptoInFiatReducers from "./member/displayCryptoInFiat";
import WalletCurrencyReducers from "./member/walletCurrency";
import eventListReducers from "./member/eventList";
import CurrencyExchangeReducers from "./member/currencyExchange";
import GameCurrencyReducers from "./member/gameCurrency";
import SubMenuReducers from "./SubMenuController";
import colorModeReducers from "./member/colorModeOpen";
import BonusReducers from "./member/bonusReducers";
import WagerConfigReducers from "./member/wagerConfig";
import detectInvalidAuthReducers from "./member/detectInvalidAuth";
import CarouselListReducers from "./member/carouselList";
import GameBoxStatusReducers from "./member/gameboxStatus";
import GameOnlinePlayerReducers from "./game/gameOnlinePlayer";
import openFreezeModalReducers from "./member/openFreezeModal";

import {
  createRouterMiddleware,
  createRouterReducer,
  push,
  ReduxRouter,
  ReduxRouterSelector,
} from "@lagunovsky/redux-react-router";
import history from "../history";
import openEventPopReducers from "./member/openEventPop";

const AllReducers = combineReducers({
  isLoading: LoadingReducers,
  isNotice: NoticeReducers,
  isDetailPage: DetailPageReducers,
  isMenuOpen: MenuReducers,
  isMenuSelect: MenuSelectReducers,
  isMemberInfo: MemberInfoReducers,
  isCurrencyExchange: CurrencyExchangeReducers,
  isGameList: GameListReducers,
  isGameLink: GameLinkReducers,
  isLoaderStatus: LoadingStatusReducers,
  isDepositLink: DepositLinkReducers,
  isGameOpen: OpenGameReducers,
  isCryptoRatio: cryptoReducers,
  isReferDetail: ReferDetailReducers,
  isOpenFullScreen: FullScreenReducers,
  isHandleAllGame: AllGameReducers,
  isLanguagePop: languagePopReducers,
  isWalletSettingPop: walletSettingPopReducers,
  isComissionListPop: comissionListPopReducers,
  isVipBar: GameVipBarReducers,
  isAccessTool: GameAccessToolReducers,
  isUploadImages: StoreFileReducers,
  isGameConfig: GameConfigReducers,
  isTransferWallet: StoreTransferWalletReducers,
  isTheme: mainThemeReducers,
  isFavoriteGameList: FavoriteGameListReducers,
  isVipDetail: storeVipDetailReducers,
  isStickyLeft: MenuStickyLeftReducers,
  isGameDescriptionPop: gameDescriptionPopReducers,
  isInboxDetail: StoreInboxReducers,
  isOpenChatRoom: openChatRoomReducers,
  isTriggerTransferTab: TriggerGameBoxTransferReducers,
  isGlobalTrigger: TriggerReducers,
  isMarqueeList: marqueeListReducers,
  isCachePop: openClearCacheReducers,
  isTriggerGameLink: triggerGameReducers,
  isInboxList: inboxListReducers,
  isGameLoading: gameLoadingReducers,
  isNowCurrency: CurrencyReducers, //目前以法幣顯示的幣別
  isWalletCurrency: WalletCurrencyReducers, //目前右上錢包幣別
  isHideZeroAmount: HideZeroAmountReducers, //隱藏零數金額
  isDisplayCryptoInFiat: DisplayCryptoInFiatReducers, //顯示加密貨幣轉換法幣
  isEventList: eventListReducers, //活動列表
  isGameCurrency: GameCurrencyReducers, //遊戲內頁幣別
  isSubMenuControl: SubMenuReducers,
  isColorMode: colorModeReducers,
  isBonus: BonusReducers,
  isWagerConfig: WagerConfigReducers,
  isCallbackInvalid: detectInvalidAuthReducers,
  isCarouselList: CarouselListReducers,
  isGameBoxStatus: GameBoxStatusReducers,
  isGameOnlinePlayer: GameOnlinePlayerReducers,
  isOpenFreezeModal: openFreezeModalReducers,
  isEventPopOpen: openEventPopReducers,
  navigator: createRouterReducer(history),
  router: createRouterReducer(history),
});

export default AllReducers;
