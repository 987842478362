export const transactionExpress = /^[0-9]{4}$/;
export const transactionExpressForInput = "[0-9]{4}";

export const passwordExpress = /^[^\s]{6,}$/;
export const passwordExpressForInput = "^[^\\s]{6,}$";

export const phoneExpress = /^\d{10}$/;
export const phoneExpressForInput = "\\d{10,15}";

export const accountExpress = /^[A-Za-z\d]{3,}$/;
export const accountExpressForInput = "^[A-Za-z\\d]{3,}$";

export const cantSpecialSymbol = "[a-zA-Zd]+";

export const disabledSpaceForInput = "^[^s]+$";
