import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Text,
  chakra,
  shouldForwardProp,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { COMMON_WEB_PATH } from "../../../constant";
import { RiVisaLine } from "react-icons/ri";
import {
  FaBitcoin,
  FaCcJcb,
  FaCcMastercard,
  FaEthereum,
  FaFacebookF,
  FaFacebookMessenger,
  FaInstagram,
  FaLine,
  FaTelegramPlane,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { FaCircleChevronRight, FaXTwitter } from "react-icons/fa6";
import { SiTether } from "react-icons/si";
import { useNavigate } from "react-router-dom";
import useCheckLogin from "../../../hook/useCheckLogin";
import { appName } from "../../../config";
import { IoQrCode } from "react-icons/io5";
import { community_link } from "../../../components/Footer/utils/communication_link";
import { useTranslation } from "react-i18next";
import Jackpot from "../../../components/v2Layout/Jackpot/Jackpot";
import JackpotWrapper from "../../../components/v2Layout/Jackpot/JackpotWrapper";
import LeaderBoard from "../../../components/v3Layout/LeaderBoard/LeaderBoard";
import { isValidMotionProp, motion } from "framer-motion";
import { gameListFromBottom } from "../../../animateConstant";
import VipOpenPage from "../../../utils/credit/vip_open_page";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const IconWrapper = ({ icon }) => {
  const cardBgColor = useColorModeValue("dark.200", "#AEAFB1");
  const cardTextColor = useColorModeValue("light.100", "dark.100");
  const hoverCardTextColor = useColorModeValue("dark.100", "light.100");
  return (
    <Box
      borderRadius={`common`}
      display={`flex`}
      alignItems={`center`}
      justifyContent={`center`}
      bg={cardBgColor}
      color={cardTextColor}
      w={{
        base: "60px",
        md: "80px",
      }}
      h={{
        base: "40px",
        md: "50px",
      }}
      fontSize={{
        base: "2rem",
        md: "2.5rem",
      }}
      cursor={`pointer`}
      _hover={{
        color: hoverCardTextColor,
      }}
    >
      <Icon as={icon} />
    </Box>
  );
};

const Coin = ({ icon, text }) => {
  return (
    <Flex alignItems={`center`} gap="3px">
      <Icon as={icon} />
      <Text fontWeight={`semibold`}>{text}</Text>
    </Flex>
  );
};

const Community = ({ icon, link }) => {
  const hoverBgColor = useColorModeValue("dark.100", "light.100");
  const hoverColor = useColorModeValue("light.100", "dark.100");

  return (
    <Box
      w="55px"
      display={`flex`}
      alignItems={`center`}
      justifyContent={`center`}
      h="55px"
      borderRadius={`full`}
      overflow="hidden"
      _hover={{
        bg: hoverBgColor,
        color: hoverColor,
      }}
    >
      <a href={link} target={`_blank`}>
        <Icon
          cursor="pointer"
          fontSize={{
            base: "1.5rem",
            lg: "2.5rem",
          }}
          as={icon}
        />
      </a>
    </Box>
  );
};

const VipCard = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`home.vip-card.${key}`, { ...props });

  const bgColor = useColorModeValue("light.200", "dark.100");

  const { isLogin, isLoading } = useCheckLogin();

  const navigate = useNavigate();

  const { facebook, instagram, youtube, twitter, tiktok, telegram, messager } =
    community_link;

  return (
    <Grid
      h={{
        base: "unset",
        // md: "270px",
        // lg: "280px",
      }}
      templateRows="repeat(6, 1fr)"
      templateColumns={{
        base: "repeat(1, 1fr)",
        sm: "repeat(2, 1fr)",
      }}
      gap={4}
      alignItems={`flex-end`}
    >
      <GridItem
        id="left-card"
        borderRadius={`common`}
        rowSpan={6}
        colSpan={1}
        // bg={bgColor}
        overflow="scroll"
        className="no-scrollbar"
      >
        {/* <Box
          py="30px"
          px={{
            "2xl": "50px",
            xl: "40px",
            base: "15px",
          }}
          h="100%"
          w="100%"
          position="relative"
          display={`flex`}
          justifyContent={`flex-end`}
          overflow="hidden"
        >
          <AbsoluteCenter
            left={{
              xl: "35%",
              "2xl": "25%",
              base: "23%",
            }}
          >
            <Image
              // opacity={{
              //   base: 0.3,
              //   sm: 0.3,
              //   "2xl": 1,
              // }}
              w={{
                base: "200px",
                md: "unset",
              }}
              src={`${COMMON_WEB_PATH}/b69-home/vip-card/crypto-image.webp`}
            />
          </AbsoluteCenter>
          <Flex
            position="relative"
            zIndex={1}
            direction={`column`}
            gap="20px"
            alignItems={`center`}
          >
            <Box>
              <Text fontWeight={`semibold`}>{i18n("left-card-title")}</Text>
            </Box>
            <Box>
              <Flex alignItems={`center`} gap="20px">
                <IconWrapper icon={RiVisaLine} />
                <IconWrapper icon={FaCcMastercard} />
                <IconWrapper icon={FaCcJcb} />
              </Flex>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  if (!isLogin) {
                    navigate("signin");
                  } else {
                    navigate("profile/deposit");
                  }
                }}
                borderColor="brand.500"
                border={`1px solid`}
                color="brand.500"
                size="lg"
                bg="none"
                rightIcon={<FaCircleChevronRight />}
              >
                {i18n("left-card-buy")}
              </Button>
            </Box>
            <Box>
              <Flex alignItems={`center`} gap="10px">
                <Coin icon={FaBitcoin} text="bitcoin" />
                <Coin icon={FaEthereum} text="ethereum" />
                <Coin icon={SiTether} text="tether" />
              </Flex>
            </Box>
          </Flex>
        </Box> */}
        <Flex
          h="100%"
          direction={"column"}
          justifyContent={"space-between"}
          gap="10px"
        >
          <Box>
            <LeaderBoard />
          </Box>
          <VipOpenPage>
            <Flex
              h="100%"
              direction={"column"}
              justifyContent={"space-between"}
              gap="10px"
            >
              <JackpotWrapper />
            </Flex>
          </VipOpenPage>
        </Flex>
      </GridItem>
      <VipOpenPage revert>
        <GridItem
          id="left-card"
          borderRadius={`common`}
          rowSpan={6}
          colSpan={1}
          // bg={bgColor}
          overflow="scroll"
          className="no-scrollbar"
        >
          <Flex
            h="100%"
            direction={"column"}
            justifyContent={"space-between"}
            gap="10px"
          >
            <JackpotWrapper />
          </Flex>
        </GridItem>
      </VipOpenPage>
      <VipOpenPage>
        <GridItem
          id="right-card-1"
          borderRadius={`common`}
          rowSpan={{
            sm: 6,
            md: 5,
            lg: 4,
          }}
          colSpan={1}
          bg={bgColor}
        >
          <ChakraBox
            {...gameListFromBottom}
            py="30px"
            px={{
              "2xl": "50px",
              xl: "40px",
              base: "15px",
            }}
            h="100%"
            w="100%"
            position="relative"
            display={`flex`}
            justifyContent={`flex-start`}
            overflow="hidden"
          >
            <Flex
              position="relative"
              zIndex={1}
              direction={`column`}
              gap="20px"
              alignItems={"flex-start"}
              w="100%"
            >
              <Box
                display={`flex`}
                flexDirection="column"
                gap="3px"
                alignItems={`center`}
              >
                <Text fontSize="1.6rem" fontWeight={`bold`}>
                  {i18n("right-card-welcome", { appName })}
                </Text>
                <Text fontSize="0.9rem" fontWeight={`medium`}>
                  {i18n("right-card-description")}
                </Text>
                <Box mt="17px">
                  <Button
                    onClick={() => {
                      if (!isLogin) {
                        navigate("signin");
                      } else {
                        navigate("profile/vip");
                      }
                    }}
                    borderColor="brand.500"
                    border={`1px solid`}
                    color="brand.500"
                    size="md"
                    bg="none"
                    rightIcon={<FaCircleChevronRight />}
                  >
                    {i18n("right-card-more")}{" "}
                  </Button>
                </Box>
              </Box>
            </Flex>
            <AbsoluteCenter
              left={{
                xl: "75%",
                base: "85%",
              }}
              w="240px"
            >
              <Image
                opacity={{
                  base: 0.3,
                  sm: 0.3,
                  "2xl": 1,
                }}
                src={`${COMMON_WEB_PATH}/b69-home/vip-card/vip-list.webp`}
              />
            </AbsoluteCenter>
          </ChakraBox>
        </GridItem>
        <GridItem
          id="right-card-2"
          borderRadius={`common`}
          rowSpan={{
            lg: 2,
            md: 1,
          }}
          colSpan={{
            sm: 2,
            md: 1,
          }}
          bg={bgColor}
          overflowX="scroll"
          display={{
            base: "none",
            md: "flex",
          }}
        >
          <ChakraBox
            {...gameListFromBottom}
            as={Flex}
            w="100%"
            h="100%"
            gap={{
              base: "15px",
              xl: `25px`,
            }}
            alignItems={`center`}
            justifyContent={`center`}
          >
            <Box>
              <Community icon={FaFacebookF} link={facebook} />
            </Box>{" "}
            <Box>
              <Community icon={FaInstagram} link={instagram} />
            </Box>{" "}
            <Box>
              <Community icon={FaXTwitter} link={twitter} />
            </Box>{" "}
            <Box>
              <Community icon={FaYoutube} link={youtube} />
            </Box>
            <Box>
              <Community icon={FaTiktok} link={tiktok} />
            </Box>
            <Box>
              <Community icon={FaTelegramPlane} link={telegram} />
            </Box>
            <Box>
              <Community icon={FaFacebookMessenger} link={messager} />
            </Box>
          </ChakraBox>
        </GridItem>{" "}
      </VipOpenPage>
    </Grid>
  );
};

export default VipCard;
