import { useEffect, useMemo, useState } from "react";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import { DAILY_REWARDS } from "../../constant";

import Coin from "../../images/CheckIn/coin.png";
import BigGift from "../../images/CheckIn/BigGift.webp";
import Check from "../../images/CheckIn/check.png";
import Roulette from "../../images/EventListSelect/active_04.webp";
import { isValidMotionProp, motion } from "framer-motion";
import {
  AbsoluteCenter,
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Image,
  Spinner,
  Text,
  chakra,
  shouldForwardProp,
} from "@chakra-ui/react";
import CommonOpacityBackground from "../../components/Common/commonOpacityBackground";
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { getCheckInEvent, receiveCheckInEvent } from "../../api/getApi";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import i18next from "i18next";
import { languageMapping } from "../../i18n/config";
import useCurrencySymbol from "../../hook/useCurrencySymbol";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/formatNumber";
import { useDispatch } from "react-redux";
import { notice } from "../../redux/action/action";
import useCheckLogin from "../../hook/useCheckLogin";
import NotFound from "../../components/ProgressBlock/NotFound";

const checkInStyle = "linear-gradient(180deg,#816bf2,#7047e5)";
const alreadyCheckInStyle = "linear-gradient(180deg,#d1d0d0,#bababa)";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const getToday = dayjs().day();

const CheckIn = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`check-in.${key}`, props);
  const i18n_reward = (key, props) =>
    t(`reward.dashboard.${key}`, { ...props });

  const [checkInList, setCheckInList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [trigger, setTrigger] = useState(false);

  const dispatch = useDispatch();

  const { isLogin } = useCheckLogin();

  const navigate = useNavigate();

  const current_symbol = useCurrencySymbol();

  const process_check_in_list = useMemo(() => {
    if (checkInList) {
      const lng = languageMapping(i18next.language);
      return checkInList.map((item) => {
        const { continuous_events_copywriting } = item || {};

        const find_copywriting =
          continuous_events_copywriting.find((item) => item.language === lng) ||
          continuous_events_copywriting?.[0];

        return {
          ...item,
          main_title: find_copywriting?.title,
          main_content: find_copywriting?.content,
        };
      });
    }
  }, [checkInList, i18next.language]);

  useEffect(() => {
    const fetchData = async () => {
      await getCheckInEvent().then((data) => {
        setCheckInList(data?.data);
      });

      setIsLoading(false);
    };

    fetchData();
  }, [trigger]);

  return (
    <CommonOpacityBackground
      className={`!bg-[rgb(0,0,0,0.3)]`}
      disabledFunction
    >
      <ChakraBox
        onClick={(e) => {
          e.stopPropagation();
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        // 0A0D0F
        minH={{
          md: "248px",
        }}
        maxH={{
          md: "calc(100vh - 60px)",
        }}
        h="100%"
        w={{
          base: "100%",
          md: "500px",
        }}
        borderRadius={`common`}
        display={"flex"}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        flexDirection={`column`}
        className="bg-black"
        overflowY="scroll"
      >
        <CloseDetailPage
          title={i18n("title")}
          className="!mb-0 px-[10px]" //傳className參數內容給CloseDetailPage
          titleClassName="text-white"
          customClose={
            <IoCloseOutline
              className="cursor-pointer hidden md:block"
              onClick={() => {
                navigate(-1);
              }}
            />
          }
        />
        <Box px="common-x">
          <Splide
            //  ref={splideRef}
            options={{
              pagination: false,
              arrows: false,
              perMove: 1,
              fixedWidth: "95%",
              gap: 10,
              lazyLoad: "nearby",
              dragMinThreshold: {
                mouse: 0,
                touch: 10,
              },
            }}
          >
            {isLoading ? (
              <SplideSlide>
                <Box
                  minH="300px"
                  borderRadius={"25px"}
                  w="100%"
                  py={{
                    base: "5px",
                    md: "20px",
                  }}
                  px={{
                    base: "5px",
                    md: "20px",
                  }}
                  mb="25px"
                  position="relative"
                  style={{ backgroundImage: `${checkInStyle}` }}
                >
                  <AbsoluteCenter>
                    <Spinner size={"xl"} variant={"brandPrimary"} />
                  </AbsoluteCenter>
                </Box>
              </SplideSlide>
            ) : process_check_in_list?.length == 0 ? (
              <NotFound />
            ) : (
              process_check_in_list?.map((item, index) => {
                const {
                  main_title,
                  main_content,
                  uid,
                  continuous_events_attribute,
                  start_date,
                  end_date,
                } = item || {};

                const get_today_attribute = continuous_events_attribute?.find(
                  (item) => item.number == getToday
                );
                //  * - 0:不可領取
                //  * - 1:可以領取
                //  * - 2:已領取
                //  * - 99:未開放
                return (
                  <SplideSlide>
                    <Box
                      key={index + "checkInList"}
                      minH="300px"
                      borderRadius={"25px"}
                      w="100%"
                      py={{
                        base: "5px",
                        md: "20px",
                      }}
                      px={{
                        base: "5px",
                        md: "20px",
                      }}
                      mb="25px"
                      style={{ backgroundImage: `${checkInStyle}` }}
                    >
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        flexDirection={"column"}
                      >
                        <Text
                          as="h3"
                          fontWeight={700}
                          mb="10px"
                          textAlign={"center"}
                          fontSize="xl"
                        >
                          {main_title || `CHECK-IN EVENT ${uid}`}
                        </Text>
                        <Text
                          as="h3"
                          fontWeight={500}
                          mb="20px"
                          fontSize={{
                            base: "xs",
                            md: "sm",
                          }}
                        >
                          {start_date} ~ {end_date}
                        </Text>
                      </Box>
                      <Grid
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        gap="10px"
                        mb="20px"
                      >
                        {continuous_events_attribute?.map(
                          (attr_item, index) => {
                            const {
                              rewards_value,
                              status,
                              number,
                              rewards_type,
                              value, //目標金額
                            } = attr_item;

                            const already_receive = status == 2;
                            const event_not_open = status == 99;
                            const you_can_not_receive = status == 0;
                            const you_can_receive = status == 1;
                            const is_last_one =
                              index === continuous_events_attribute.length - 1;

                            const reward_image = () => {
                              switch (rewards_type) {
                                case "amounts":
                                  return {
                                    imgSrc: is_last_one ? BigGift : Coin,
                                    imgText: current_symbol,
                                    imgWidth: is_last_one ? "110px" : "30px",
                                    REWARD: rewards_value,
                                  };
                                case "multiplier":
                                  return {
                                    imgSrc: is_last_one ? BigGift : Coin,
                                    imgText: current_symbol,
                                    imgWidth: is_last_one ? "110px" : "30px",
                                    REWARD: rewards_value * value,
                                  };
                                case "roulettes":
                                  return {
                                    imgSrc: is_last_one ? BigGift : Roulette,
                                    imgText: i18n_reward("times"),
                                    imgWidth: is_last_one ? "110px" : "30px",
                                    imgClass: "animate-spin",
                                    REWARD: rewards_value,
                                  };
                                default:
                                  return {
                                    imgSrc: is_last_one ? BigGift : Coin,
                                    imgText: rewards_value,
                                    imgWidth: is_last_one ? "110px" : "30px",
                                    REWARD: rewards_value,
                                  };
                              }
                            };

                            const {
                              imgSrc,
                              imgText,
                              imgWidth,
                              imgClass,
                              REWARD,
                            } = reward_image();

                            return (
                              <GridItem
                                colSpan={
                                  index ==
                                  continuous_events_attribute.length - 1
                                    ? 2
                                    : 1
                                }
                                position="relative"
                                cursor={"pointer"}
                              >
                                <Box key={index + "check-in-attribute"}>
                                  <Box
                                    onClick={async () => {
                                      if (number != getToday) {
                                        return;
                                      }
                                      if (event_not_open) {
                                        dispatch(
                                          notice({
                                            title: "Notice",
                                            type: "warning",
                                            content: i18n("event_not_open"),
                                          })
                                        );
                                        return;
                                      } else if (you_can_not_receive) {
                                        dispatch(
                                          notice({
                                            title: "Notice",
                                            type: "warning",
                                            content: i18n(
                                              "you_can_not_receive"
                                            ),
                                          })
                                        );
                                        return;
                                      } else if (already_receive) {
                                        return;
                                      } else if (you_can_receive) {
                                        await receiveCheckInEvent({
                                          checkInUid: uid,
                                          attributeUid: attr_item.uid,
                                        }).then((data) => {
                                          dispatch(
                                            notice({
                                              title: "Notice",
                                              type: "warning",
                                              content: i18n("receive_success"),
                                            })
                                          );
                                          setTrigger(!trigger);
                                        });
                                      }
                                    }}
                                    border={
                                      getToday === index + 1 ? "2px solid" : ""
                                    }
                                    borderColor={
                                      getToday === index + 1 ? "brand.500" : ""
                                    }
                                    h="95px"
                                    w="100%"
                                    boxShadow={"md"}
                                    borderRadius={"lg"}
                                    py="4px"
                                    px="7px"
                                    display="flex"
                                    flexDirection={"column"}
                                    justifyContent={"flex-end"}
                                    position="relative"
                                    bg={
                                      already_receive
                                        ? ""
                                        : "rgb(146,119,248,.5)"
                                    }
                                    bgGradient={
                                      already_receive
                                        ? "linear(to-b,#d1d0d0,#bababa)"
                                        : ""
                                    }
                                    className={`
                                  ${already_receive ? "filter-gray" : ""}
                                `}
                                  >
                                    <Text
                                      position="absolute"
                                      top="5px"
                                      left="5px"
                                      color="white"
                                      fontWeight={600}
                                      fontSize={{
                                        base: "sm",
                                        md: "md",
                                      }}
                                    >
                                      {i18n("day", { day: index + 1 })}
                                    </Text>

                                    <Image
                                      src={imgSrc}
                                      w={imgWidth}
                                      className={imgClass}
                                      alignSelf={"center"}
                                    />
                                    <Text
                                      color="white"
                                      textAlign={"center"}
                                      mb="0px"
                                      fontSize={{
                                        base: "sm",
                                        md: "md",
                                      }}
                                    >
                                      {REWARD} {imgText}
                                    </Text>
                                  </Box>
                                  {already_receive && (
                                    <Image
                                      position="absolute"
                                      right="-21px"
                                      top="-34px"
                                      src={Check}
                                    />
                                  )}
                                </Box>
                              </GridItem>
                            );
                          }
                        )}
                      </Grid>

                      <Divider my="10px" />

                      {/* <Text
                      textAlign={"center"}
                      mb="10px"
                      fontWeight={700}
                      fontSize={{
                        base: "md",
                        md: "2xl",
                      }}
                    >
                      {i18n("CHECK-IN RULES")}
                    </Text> */}
                      <Box
                        borderRadius={"common"}
                        bg="rgb(146,119,248,.5)"
                        p="10px"
                        shadow="md"
                      >
                        {/* <Flex direction={"column"} gap="15px">
                        {continuous_events_attribute?.map(
                          (attr_item, index) => {
                            const { number } = attr_item || {};
                            return (
                              <Box>
                                <Text>第{number}天</Text>
                              </Box>
                            );
                          }
                        )}
                        
                      </Flex> */}
                        <Flex
                          direction={"column"}
                          alignItems={"flex-start"}
                          justifyContent={"space-between"}
                          gap="50px"
                        >
                          <Flex w="100%" direction={"column"}>
                            <Text
                              alignSelf={"center"}
                              fontWeight={700}
                              fontSize={{
                                base: "md",
                                md: "lg",
                              }}
                            >
                              {i18n("check-in-day-need-complete-mission", {
                                day: get_today_attribute?.number,
                              })}
                            </Text>
                            <Divider my="10px" variant="dashed" />{" "}
                            <Flex
                              gap="10px"
                              direction={"column"}
                              alignItems={"flex-start"}
                              fontSize={{
                                base: "sm",
                                md: "md",
                              }}
                            >
                              <Text fontWeight={600}>
                                {i18n("mission_type")}：
                                {i18n_reward(get_today_attribute?.type)}{" "}
                                {i18n_reward(get_today_attribute?.subtype)}{" "}
                                {formatNumber(get_today_attribute?.value)}{" "}
                                {current_symbol}
                              </Text>
                              <Text fontWeight={600}>
                                {i18n("reward_limit")}({i18n("max_can_receive")}
                                )：
                                {get_today_attribute?.enable_rewards_limit == 1
                                  ? ""
                                  : i18n("no_limit")}{" "}
                                {i18n_reward(
                                  get_today_attribute?.rewards_limit_type,
                                  {
                                    current_symbol:
                                      get_today_attribute?.currency,
                                  }
                                )}{" "}
                                {get_today_attribute?.rewards_limit_value
                                  ? formatNumber(
                                      get_today_attribute?.rewards_limit_value
                                    )
                                  : ""}{" "}
                              </Text>
                              <Text fontWeight={600}>
                                {i18n("wager_limit")}：
                                {get_today_attribute?.enable_wagering_limit == 1
                                  ? ""
                                  : i18n("no_limit")}{" "}
                                {get_today_attribute?.wagering_limit_value
                                  ? formatNumber(
                                      get_today_attribute?.wagering_limit_value
                                    )
                                  : ""}{" "}
                                {i18n_reward(
                                  get_today_attribute?.wagering_limit_type ==
                                    "multiplier"
                                    ? "multiplier_2"
                                    : get_today_attribute?.wagering_limit_type,
                                  {
                                    current_symbol:
                                      get_today_attribute?.currency,
                                  }
                                )}{" "}
                              </Text>
                            </Flex>
                          </Flex>
                          <Box>
                            <Flex
                              fontWeight={700}
                              direction={"column"}
                              gap="10px"
                            >
                              <Text fontSize={"xs"}>＊{i18n("hint1")}</Text>
                            </Flex>
                          </Box>
                        </Flex>
                      </Box>
                    </Box>
                  </SplideSlide>
                );
              })
            )}
          </Splide>
        </Box>
      </ChakraBox>
    </CommonOpacityBackground>
  );
};

export default CheckIn;
