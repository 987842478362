import { Box, Button, Flex, Icon, Image, Text } from "@chakra-ui/react";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { Link } from "react-router-dom";
import useAppDownloadLink from "./hooks/useAppDownloadLink";
import { FaMobileAlt } from "react-icons/fa";
import InApp from "detect-inapp";
import AppWrapper from "./AppWrapper";
import useDeviceType from "../../hook/useDeviceType";
import { useTranslation } from "react-i18next";

const HeaderAppDownload = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const [closedAppDownload, setClosedAppDownload] = useState(false);

  const handleCloseAppDownload = () => {
    setClosedAppDownload(true);
  };

  const device = useDeviceType();

  const app_download_link = useAppDownloadLink();
  return (
    <>
      <AppWrapper
        show={device === "Mobile" || device === "Tablet"}
        closedAppDownload={closedAppDownload}
      >
        <Box
          h="50px"
          w="100%"
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap="10px"
          py="5px"
          px="10px"
          bgGradient="linear(to-r, brand.500,brand.800,brand.500 ,brand.800)"
          position="relative"
        >
          <Box
            top="0"
            left="0px"
            bg="rgb(0,0,0,0.5)"
            w="22.5px"
            h="22.5px"
            borderBottomRightRadius={"full"}
            position="absolute"
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            onClick={handleCloseAppDownload}
          >
            <Icon mr="4.5px" as={IoMdClose} />
          </Box>
          <Flex gap="10px">
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={"common"}
              w="40px"
              h="40px"
            >
              <Image
                src={`${process.env.REACT_APP_I18N_PATH}/logo/logo_144.png`}
              />
            </Box>
            <Box
              h="100%"
              display="flex"
              flexDirection={"column"}
              justifyContent={"space-between"}
              fontWeight={600}
              fontSize="xs"
            >
              <Text fontWeight={700} fontSize="sm">
                {i18n("download-app")}
              </Text>
              <Text fontSize="9px">{i18n("and get better experience")}</Text>
            </Box>
          </Flex>
          <Button
            as={Link}
            to={app_download_link}
            target="_blank"
            variant={"brandPrimary"}
            fontSize="sm"
            size="sm"
            rightIcon={<Icon as={FaMobileAlt} />}
          >
            {i18n("DOWNLOAD NOW")}
          </Button>
        </Box>
      </AppWrapper>
    </>
  );
};

export default HeaderAppDownload;
