import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { languageMapping } from "../i18n/config";
import i18next from "i18next";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

const useProcessEventList = ({ event_list } = {}) => {
  const walletCurrency = useSelector((state) => state.isWalletCurrency);

  const current_time = dayjs().format("YYYY-MM-DD HH:mm:ss");

  const process_event_list = useMemo(() => {
    return event_list
      ?.filter((item) => {
        const currency_attribute = item?.events_attributes?.find(
          (item) => item.attribute_currency === walletCurrency
        );
        return currency_attribute?.attribute_is_active === 1;
      })

      .map((item) => {
        const {
          start_time,
          end_time,
          event_code,
          event_show_type,
          events_copywriting,
          participating_platforms,
          uid,
          reset_cycle,
          events_attributes,
          is_show_carousel,
          is_show_pop_up,
        } = item || {};

        const find_event_attribute = events_attributes?.find(
          (item) => item.attribute_currency === walletCurrency
        );
        const {
          attribute_currency,

          attribute_rewards_position, //分配到特定遊戲錢包
          attribute_rewards_specify_wallet, //指定遊戲錢包

          attribute_rewards_type, //獎勵類型 倍率/固定值
          attribute_rewards_value, //獎勵值

          attribute_subtype, //條件類型 > < =
          attribute_type,
          attribute_value, //條件值

          enable_rewards_limit, //是否限制獎勵次數, 1,0
          rewards_limit_type, //限制獎勵最大值 類型 倍率/固定值
          rewards_limit_value, //限制獎勵最大值 ex:限制儲值最高就是10000，超過了也是會依照10000去贈送點數

          enable_wagering_limit, //是否限制洗馬量  1,0
          wagering_limit_type, //限制洗馬量 類型 倍率/固定值
          wagering_limit_value, //限制洗馬量
        } = find_event_attribute || {};

        const filter_events_copywriting = events_copywriting?.filter((item) => {
          return item?.title !== "";
        });

        const find_language_copywriting =
          filter_events_copywriting?.find(
            (item) => item.language === languageMapping(i18next.language)
          ) || filter_events_copywriting[0];
        return {
          is_between_time: dayjs(current_time).isBetween(start_time, end_time),
          start_time,
          end_time,
          event_code,
          event_show_type,
          is_show_carousel,
          reward_title: find_language_copywriting?.title,
          reward_slogan: find_language_copywriting?.slogan,
          reward_content: find_language_copywriting?.content,
          reward_sub_title: find_language_copywriting?.subtitle,
          copywriting_is_active: find_language_copywriting?.is_active,
          participating_platforms,
          uid,
          reset_cycle,
          reward_condition: {
            currency: attribute_currency,
            wagering: {
              enable_wagering_limit,
              wagering_limit_type,
              wagering_limit_value,
            },
            reward_limit: {
              enable_rewards_limit,
              rewards_limit_type,
              rewards_limit_value,
              attribute_rewards_type,
              attribute_rewards_value,
            },
            rewards_type: {
              attribute_rewards_position,
              attribute_rewards_specify_wallet,
              attribute_subtype,
              attribute_type,
              attribute_value,
            },
          },
          is_show_pop_up: is_show_pop_up,
        };
      });
  }, [event_list, i18next]);

  return process_event_list;
};

export default useProcessEventList;
