import React from "react";
import { MdOutlineSearch } from "react-icons/md";
import { RiMenuSearchFill } from "react-icons/ri";
import ListSelect from "./listSelect";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import Roulette from "../../images/EventListSelect/active_04.webp";
import CheckIn from "../../images/EventListSelect/active_07.webp";
import { COMMON_ALT } from "../../constant";
import {
  Box,
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { use } from "i18next";
import { useDispatch } from "react-redux";
import { notice } from "../../redux/action/action";
import useCheckLogin from "../../hook/useCheckLogin";
import CreditPage from "../../utils/credit/credit_page";

const MobileSearch = ({ listHidden }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const dispatch = useDispatch();

  const { isLogin } = useCheckLogin();

  const { gamePlatform } = useParams();

  const iconBg = useColorModeValue("light.200", "dark.100");
  const searchBg = useColorModeValue("light.200", "dark.100");
  const searchText = useColorModeValue("light.400", "light.200");

  return (
    <Flex direction={`column`} gap="8px" px="common-y" mb="10px">
      <Flex alignItems={`center`} gap="8px" height="44px">
        <Link to="search">
          <Flex
            alignItems={`center`}
            justifyContent={`center`}
            borderRadius={`common`}
            width="44px"
            height="44px"
            bg={iconBg}
          >
            <Icon as={MdOutlineSearch} fontSize="3xl" color="brand.500" />
          </Flex>
        </Link>
        {window.roulettes_activity == 1 ? (
          <Box>
            <Link to={"n-roulette"}>
              <Flex
                alignItems={`center`}
                justifyContent={`center`}
                borderRadius={`common`}
                width="44px"
                height="44px"
                bg={iconBg}
              >
                <Image
                  src={Roulette}
                  cursor={`pointer`}
                  maxWidth={`44px`}
                  borderRadius={`common`}
                  className="animate-spin"
                  alt={COMMON_ALT}
                />
              </Flex>
            </Link>
          </Box>
        ) : null}
        {window.check_in_activity == 1 ? (
          <Box>
            <Link to={"checkin"}>
              <Flex
                alignItems={`center`}
                justifyContent={`center`}
                borderRadius={`common`}
                width="44px"
                height="44px"
                bg={iconBg}
              >
                <Image
                  src={CheckIn}
                  cursor={`pointer`}
                  maxWidth={`44px`}
                  borderRadius={`common`}
                  alt={COMMON_ALT}
                />
              </Flex>
            </Link>
          </Box>
        ) : null}

        <Link className="w-full" to="casino-group">
          <Flex
            alignItems={`center`}
            justifyContent={`space-between`}
            borderRadius={`common`}
            width={`100%`}
            height="44px"
            bg={searchBg}
            color={searchText}
            px="10px"
          >
            <Text as="span">
              {gamePlatform && gamePlatform !== "all"
                ? gamePlatform
                : i18n("All providers")}
            </Text>
            <Icon as={RiMenuSearchFill} fontSize="3xl" color="brand.500" />
          </Flex>
        </Link>
      </Flex>
      <Box>{listHidden || <ListSelect />}</Box>
    </Flex>
  );
};

export default MobileSearch;
