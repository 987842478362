import React, { Fragment, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "../../components/Carousel";
import GameCatalog from "../../components/GameCatalog";
import { useTranslation } from "react-i18next";
import SupplierList from "../../components/GameSelect/SupplierList";
import { useLocation, useNavigation } from "react-router";
import TopTab from "./components/topTab";
import useSubTypeList from "../../hook/useSubTypeList";
import { filterType } from "../../hook/useGameListFilter";
import useCasinoType from "../../hook/useCasinoType";
import UserClickGameCatalog from "../../components/UserClickGameCatalog";
import MarqueeList from "../../components/marquee/marqueeList";
import { Box } from "@chakra-ui/react";
import useCurrentCurrencyIsCrypto from "../../hook/useCurrentCurrencyIsCrypto";
import MobileSearch from "../Search/mobile-search";
import EventCarousel from "./components/event_carousel";
import VipCard from "./components/vipCard";
import HomeRank from "./components/home_rank";
import ClassicType from "./components/classicType";
import CreditPage from "../../utils/credit/credit_page";
import VipOpenPage from "../../utils/credit/vip_open_page";

const Home = ({}) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const subList = useSubTypeList();

  const casinoType = useCasinoType();

  const dispatch = useDispatch();
  const platformList = useSelector((state) => state.isHandleAllGame.platform);
  const handleAllGame = useSelector((state) => state.isHandleAllGame); //redux純取值用useSelector就好 不用dispatch
  const loadingStatus = useSelector((state) => state.isLoaderStatus);
  //把Redux store 中的 isHandleAllGame 属性，儲存在 handleAllGame 變量中
  const currentCurrency = useSelector((state) => state.isWalletCurrency); //從redux store中取isNowCurrency的值 用來判斷幣別

  const {
    platform,
    topGameList,
    newGameList,
    categorizedGames,
    exclusiveBrandGames,
  } = handleAllGame; //從handleAllGame中提取了 platform、gameList 和 gameType 属性。可以在组件中直接使用这些属性，而不必每次都通过handleAllGame 来訪問它们。

  const location = useLocation();
  const { pathname } = location;

  const currentCurrencyIsCrypto = useCurrentCurrencyIsCrypto();

  const topGames = filterType({
    gameList: topGameList,
    casinoType: casinoType,
  });

  const newGames = filterType({
    gameList: newGameList,
    casinoType: casinoType,
  })?.reverse();
  const lobbyGames = useMemo(() => {
    return filterType({
      gameList: categorizedGames?.Lobby,
      casinoType: "live-casino",
    }); //從redux取categorizedGames裡面的Lobby 記得問號防呆
  }, [categorizedGames]);

  const subGames = useMemo(() => {
    return subList
      ?.filter((item) => item !== "MyFavorite" && item !== "ClickGames")
      ?.map((subType, index) => {
        const subGamelist = filterType({
          gameList: categorizedGames?.[subType]?.filter((game) => {
            return game.currencyCode?.includes(currentCurrency);
          }), //加上filter檢查遊戲的 currencyCode 是否包含 currentCurrency
          casinoType: casinoType,
        });

        return (
          subGamelist?.length > 10 && (
            <Fragment key={subType}>
              <GameCatalog
                list={subGamelist}
                wrapperClassName={subGamelist?.length === 0 && "hidden"}
                mainTitle={subType}
              />
            </Fragment>
          )
        );
      });
  }, [categorizedGames, subList, currentCurrency, currentCurrencyIsCrypto]);

  return (
    <Fragment>
      <Box as="section" display={{ md: "none", base: "block" }}>
        <TopTab />
      </Box>
      <Box as="section" py="common-y" px="common-y">
        <Carousel />
      </Box>
      <Box as="section" mb="10px" pb={{ md: "0", base: "0" }} px="common-y">
        <MarqueeList />
      </Box>
      <Box as="section" display={{ md: "none", base: "block" }}>
        <MobileSearch listHidden />
      </Box>
      <CreditPage>
        <Box
          as="section"
          mt={{
            base: "0px",
            md: "30px",
          }}
          px="common-y"
        >
          <VipCard />
        </Box>
      </CreditPage>

      <Box>
        <UserClickGameCatalog hiddenButtonNavigator dontFilter />
        <GameCatalog
          list={topGames}
          wrapperClassName={topGames?.length === 0 && "hidden"}
          mainTitle={"Top"}
          observeFalse
          swiperProps={{
            customPerView: "popular-layout",
          }}
          fullImageLayout
          itemClass="2xl:h-[225px] xl:h-[265px] h-[180px]"
          hiddenButtonNavigator
        />

        {/* <Box as="section" my={"5px"} px="common-y">
          <HomeRank />
        </Box> */}
        <CreditPage>
          <Box as="section" my={"5px"} px="common-y">
            <ClassicType />
          </Box>
        </CreditPage>
        <EventCarousel />
        <GameCatalog
          list={exclusiveBrandGames}
          wrapperClassName={exclusiveBrandGames?.length === 0 && "hidden"}
          mainTitle={"ExclusiveGame"}
          itemClass="xl:!h-[300px] h-[180px]"
          swiperProps={{
            customPerView: "new-game-layout",
          }}
          hiddenButtonNavigator
          fullImageLayout
        />
      </Box>
      {/* <Box>{subGames}</Box> */}
      {/* <Ranking /> */}
    </Fragment>
  );
};

export default React.memo(Home);
