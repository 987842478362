import React from "react";
import { Navigate, Outlet } from "react-router";
import Wrapper from "../components/Wrapper";
import Home from "../pages/Home/Home";
import Signup from "../pages/Signup/Signup";
import Signin from "../pages/Singin/Signin";
import Deposit from "../pages/Deposit/Deposit";
import BetRecord from "../pages/BetRecord/BetRecord";
import Transaction from "../pages/Transaction/Transaction";
import AllPlatform from "../pages/AllPlatform/AllPlatform";
import Inbox from "../pages/Inbox/Inbox";
import InboxDetail from "../pages/Inbox/InboxDetail";
import DownloadApp from "../pages/App/DownloadApp";
import CheckIn from "../pages/CheckIn/CheckIn";
import Authenticator from "./Authenticator";
import GameBox from "../pages/Game/GameBox";
import OverflowHidden from "./OverflowHidden";
import DepositIframe from "../pages/Deposit/DepositIframe";
import Withdraw from "../pages/Withdraw/Withdraw";
import WalletSetting from "../components/SignInUp/components/WalletSetting";
import Settings from "../pages/Settings/Settings";
import Live from "../pages/Live/Live";
import Sports from "../pages/Sports/Sports";
import ScrollToTop from "./ScrollToTop";
import GameWrap from "../pages/Game/GameWrap";
import SettingIndex from "../pages/PROFILE_SETTING/settingIndex";
import SimpleProfile from "../pages/Profile/simpleProfile";
import GamePlatformPage from "../pages/gamePlatformPage/GamePlatformPage";
import CasinoGroups from "../pages/CasinoGroups/CasinoGroups";
import SimpleRewards from "../pages/Rewards/simpleRewards";
import SimpleVip from "../pages/Vip/simpleVip";
import VipDetail from "../pages/Vip/components/vipDetail";
import SimpleReferCode from "../pages/ReferCode/simpleReferCode";
import PersonalData from "../pages/Settings/pages/personalData";
import BindIdCard from "../pages/Settings/pages/bindIdCard";
import BindBankAccount from "../pages/Settings/pages/bindBankAccount";
import SetTransactionPassword from "../pages/Settings/pages/setTransactionPassword";
import BindSuccess from "../pages/Settings/pages/bindSuccess";
import ResetPassword from "../pages/Settings/pages/resetPassword";
import ResetPasswordSuccess from "../pages/Settings/pages/resetPasswordSuccess";
import ResetWithdrawPassword from "../pages/Settings/pages/resetWithdrawPassword";
import ResetWithdrawPasswordSuccess from "../pages/Settings/pages/resetWithdrawPasswordSuccess";
import UserSecureVerity from "../pages/Settings/pages/userSecureVerity";
import PointQuery from "../pages/PointQuery/PointQuery";
import DepositSuccess from "../pages/Deposit/DepositSuccess";
import Search from "../pages/Search/search";
import GameSubTypeList from "../pages/gameSubTypeList/gameSubTypeList";
import GameConfirm from "../pages/Game/components/gameConfirm";
import WithdrawSuccess from "../pages/Withdraw/withdrawSuccess";
import Lottery from "../pages/Lottery/Lottery";
import AboutUs from "../components/Rules/AboutUs";
import Privacy from "../components/Rules/Privacy";
import DepositAndWithdraw from "../components/Rules/DepositAndWithdraw";
import GameTeaching from "../components/Rules/GameTeaching";
import CommomProblems from "../components/Rules/CommomProblems";
import HelpCenter from "../components/Rules/HelpCenter";
import Responsibility from "../components/Rules/Responsibility";
import Recommend from "../components/Rules/Recommend";
import ServicePage from "../pages/Service/ServicePage";
import Roulette from "../pages/Roulette/roulette";
import EventPage from "../pages/EventList/EventPage";
import EventDetail from "../pages/EventList/EventDetail";
import DepositStepTwo from "../pages/Deposit/DepositStepTwo";
import DepositStepVip from "../pages/Deposit/DepositStepVip";
import DepositStepVipInput from "../pages/Deposit/DepositStepVipInput";
import Slot from "../pages/Slot/Slot";
import NewProfile from "../pages/Profile/newProfile";
import DepositStepNormalCrypto from "../pages/Deposit/DepositStepNormalCrypto";
import Blog from "../pages/Blog/Blog";
import BlogDetail from "../pages/Blog/BlogDetail";
import VerifyEmail from "../pages/Settings/pages/verifyEmail";
import RouletteNew from "../pages/Roulette/roulette_new";
import SendPoints from "../pages/SendPoints/SendPoints";
import SendPointsConfirm from "../pages/SendPoints/SendPointsConfirm";
import SimpleRebate from "../pages/Rebate/simpleRebate";
import SlotV2 from "../pages/Slot/Slot_v2";
import FishV2 from "../pages/Fish/Fish_v2";
import ArcadeV2 from "../pages/Arcade/Arcade_v2";
import BlockChainV2 from "../pages/BlockChain/BlockChain_v2";
import LotteryV2 from "../pages/Lottery/Lottery_v2";
import LiveV2 from "../pages/Live/Live_v2";
import SportsV2 from "../pages/Sports/Sports_v2";
import TableV2 from "../pages/Table/Table_v2";
import GamePlatformPageV2 from "../pages/gamePlatformPage/GamePlatformPage_v2";
import GameConfirmSelector from "../pages/Game/components/gameConfirmSelector";
import SearchVersionSelector from "../pages/Search/searchVersionSelector";
import CasinoGroupsVersionSelector from "../pages/CasinoGroups/CasinoGroupsVersionSelector";
import SigninVersionSelector from "../pages/Singin/SigninVersionSelector";
import SignupVersionSelector from "../pages/Signup/SignupVersionSelector";
import WrapperV2 from "../components/Wrapper_v2";
import GameWrapV2 from "../pages/Game/GameWrap_v2";
import BingoV2 from "../pages/Bingo/Bingo_v2";
import SlotV3 from "../pages/Slot/Slot_v3";
import GamePlatformPageV3 from "../pages/gamePlatformPage/GamePlatformPage_v3";
import WrapperV3 from "../components/Wrapper_v3";
import LiveV3 from "../pages/Live/Live_v3";
import BingoV3 from "../pages/Bingo/Bingo_v3";
import FishV3 from "../pages/Fish/Fish_v3";
import ArcadeV3 from "../pages/Arcade/Arcade_v3";
import BlockChainV3 from "../pages/BlockChain/BlockChain_v3";
import LotteryV3 from "../pages/Lottery/Lottery_v3";
import SportsV3 from "../pages/Sports/Sports_v3";
import TableV3 from "../pages/Table/Table_v3";
import GameWrapV3 from "../pages/Game/GameWrap_v3";
import EventPageV3 from "../pages/EventList/EventPage_v3";
import EventPageV2 from "../pages/EventList/EventPage_v2";
import Bingo from "../pages/Bingo/Bingo";
import TermsAndService from "../components/Rules/TermsAndService";

const isCredit = process.env.REACT_APP_CREDIT == 1;

export const common_route = [
  {
    path: "c-roulette",
    label: "c-roulette",
    element: <Roulette />,
  },
  {
    path: "n-roulette",
    label: "n-roulette",
    element: <RouletteNew />,
  },
  {
    path: "service-group",
    label: "serviceGroup",
    element: <ServicePage />,
  },

  {
    path: "signup",
    label: "signup",
    // hidden: isCredit,
    element: <SignupVersionSelector />,
  },
  {
    path: "signin",
    label: "signin",
    element: <SigninVersionSelector />,
  },
  {
    path: "deposit/payment-method/:uid",
    label: "inbox",
    element: (
      <Authenticator>
        <DepositStepTwo />
      </Authenticator>
    ),
    // hidden: isCredit,
    profile: true,
    children: [
      {
        path: "deposit-page",
        label: "inbox",
        element: <DepositIframe />,
        profile: true,
      },
    ],
  },
  {
    path: "deposit/payment-vip/:uid",
    label: "inbox",
    element: (
      <Authenticator>
        <DepositStepVipInput />
      </Authenticator>
    ),
    // hidden: isCredit,
    profile: true,
  },
  {
    path: "deposit/payment-crypto/:uid",
    label: "inbox",
    element: (
      <Authenticator>
        <DepositStepNormalCrypto />
      </Authenticator>
    ),
    // hidden: isCredit,
    profile: true,
  },
  {
    path: "deposit/payment-vip-success/:uid",
    label: "inbox",
    element: (
      <Authenticator>
        <DepositStepVip />
      </Authenticator>
    ),
    // hidden: isCredit,
    profile: true,
  },

  {
    path: "deposit",
    label: "deposit",
    element: (
      <Authenticator>
        <Deposit />
      </Authenticator>
    ),
    // hidden: isCredit,
    children: [
      {
        path: "deposit-success",
        label: "deposit-success",
        element: <DepositSuccess />,
      },
    ],
    profile: true,
  },
  {
    path: "betRecord",
    label: "betRecord",
    element: (
      <Authenticator>
        <BetRecord />
      </Authenticator>
    ),
    profile: true,
  },
  {
    path: "transaction",
    label: "transaction",
    element: (
      <Authenticator>
        <Transaction />
      </Authenticator>
    ),
    profile: true,
  },
  {
    path: "AllPlatform",
    label: "AllPlatform",
    element: (
      <Authenticator>
        <AllPlatform />
      </Authenticator>
    ),
  },
  {
    path: "inbox",
    label: "inbox",
    element: (
      <Authenticator>
        <Inbox />
      </Authenticator>
    ),
    children: [
      {
        path: ":inboxId",
        label: "inbox",
        element: <InboxDetail />,
        profile: true,
      },
    ],
    profile: true,
  },
  {
    path: "app",
    label: "app",
    element: <DownloadApp />,
  },
  {
    path: "checkin",
    label: "checkin",
    element: (
      <Authenticator>
        <CheckIn />{" "}
      </Authenticator>
    ),
  },
  {
    path: "deposit/depositfund",
    label: "depositfund",
    element: <DepositIframe />,
  },
  {
    path: "withdraw",
    label: "withdraw",
    hidden: isCredit,
    element: (
      <Authenticator>
        <Withdraw />
      </Authenticator>
    ),
    children: [
      {
        path: "withdraw-success",
        label: "withdraw-success",
        element: <WithdrawSuccess />,
      },
    ],

    profile: true,
  },
  {
    path: "send-points",
    label: "send-points",
    element: (
      <Authenticator>
        <SendPoints />
      </Authenticator>
    ),
    profile: true,
    children: [
      {
        path: "send-points-confirm",
        label: "send-points-confirm",
        element: (
          <Authenticator>
            <SendPointsConfirm />
          </Authenticator>
        ),
      },
    ],
  },
  // {
  //   path: "data",
  //   label: "profileData",
  //   element: (
  //     <Authenticator>
  //       <SimpleProfile />
  //     </Authenticator>
  //   ),
  //   profile: true,
  //   children: [
  //     {
  //       path: "point-query/:to?",
  //       label: "point-query",
  //       element: (
  //         <Authenticator>
  //           <PointQuery />
  //         </Authenticator>
  //       ),
  //     },
  //   ],
  // },
  {
    path: "data",
    label: "profileData",
    element: (
      <Authenticator>
        <NewProfile />
      </Authenticator>
    ),
    profile: true,
    children: [
      {
        path: "game-confirm/:gameName/:to/:gameUid",
        label: "game-confirm",
        element: (
          <>
            <GameConfirmSelector />
            <Outlet />
          </>
        ),
      },
    ],
  },

  {
    path: "walletsettings",
    label: "walletsettings",
    element: <WalletSetting />,
  },
  {
    path: "settings",
    label: "settings",
    element: <Settings />,
    children: [
      {
        path: "personal-data",
        label: "personal-data",
        element: (
          <Authenticator>
            <PersonalData />
          </Authenticator>
        ),
        profile: true,
      },
      {
        path: "verify-email",
        label: "verify-email",
        element: (
          <Authenticator>
            <VerifyEmail />
          </Authenticator>
        ),
      },
      {
        path: "bind-id-card",
        label: "bind-id-card",
        element: (
          <Authenticator>
            <BindIdCard />
          </Authenticator>
        ),
        profile: true,
      },

      {
        path: "bind-bank-account",
        label: "bind-bank-account",
        element: (
          <Authenticator>
            <BindBankAccount />
          </Authenticator>
        ),
        profile: true,
      },
      {
        path: "bind-transaction-password",
        label: "bind-transaction-password",
        element: (
          <Authenticator>
            <SetTransactionPassword />
          </Authenticator>
        ),
        profile: true,
      },
      {
        path: "bind-success",
        label: "bind-success",
        element: (
          <Authenticator>
            <BindSuccess />
          </Authenticator>
        ),
        profile: true,
      },
      {
        path: "reset-password",
        label: "reset-password",
        element: (
          <Authenticator>
            <ResetPassword />
          </Authenticator>
        ),
        profile: true,
      },
      {
        path: "reset-password-success",
        label: "reset-password-success",
        element: (
          <Authenticator>
            <ResetPasswordSuccess />
          </Authenticator>
        ),
        profile: true,
      },
      {
        path: "reset-withdraw-password",
        label: "reset-withdraw-password",
        hidden: isCredit,
        element: (
          <Authenticator>
            <ResetWithdrawPassword />
          </Authenticator>
        ),
        profile: true,
      },
      {
        path: "reset-withdraw-password-success",
        label: "reset-withdraw-password-success",
        hidden: isCredit,
        element: (
          <Authenticator>
            <ResetWithdrawPasswordSuccess />
          </Authenticator>
        ),
        profile: true,
      },
      {
        path: "user-secure-verity",
        label: "user-secure-verity",
        element: <UserSecureVerity />,
        profile: true,
      },
    ],
    profile: true,
  },
  {
    path: "profile/*",
    label: "profile",
    exact: true,
    element: (
      <Authenticator>
        <SettingIndex />
      </Authenticator>
    ),
    profile: true,
  },
  {
    path: "casino-group",
    label: "casino-group",
    element: <CasinoGroupsVersionSelector />,
  },
  {
    path: "search",
    label: "search",
    element: <SearchVersionSelector />,
    children: [
      {
        path: "game-confirm/:gameName/:to/:gameUid",
        label: "game-confirm",
        element: (
          <>
            <GameConfirmSelector />
            <Outlet />
          </>
        ),
      },
    ],
  },
  {
    path: "rewards",
    label: "rewards",
    // hidden: isCredit,
    element: (
      <Authenticator>
        <SimpleRewards />
      </Authenticator>
    ),
    profile: true,
  },
  {
    path: "my-rebate",
    label: "my-rebate",
    element: (
      <Authenticator>
        <SimpleRebate />
      </Authenticator>
    ),
    profile: true,
  },

  {
    path: "vip",
    label: "vip",
    hidden: isCredit ? true : window.vip_open == "0" ? true : false,
    element: (
      <Authenticator>
        <SimpleVip />
      </Authenticator>
    ),
    children: [
      {
        path: ":vipId",
        label: "vip",
        element: <VipDetail />,
        profile: true,
      },
    ],
    profile: true,
  },
  {
    path: "game-confirm/:gameName/:to/:gameUid",
    label: "game-confirm",
    element: (
      <>
        <GameConfirmSelector />
        <Outlet />
      </>
    ),
  },
];

export const rules_route = [
  {
    path: "/policies/about-us",
    label: "about-us",
    element: (
      <Wrapper scrollToTop>
        <AboutUs />
        <Outlet />
      </Wrapper>
    ),
  },
  {
    path: "/policies/terms-and-service",
    label: "Service",
    element: (
      <Wrapper scrollToTop>
        <TermsAndService />
        <Outlet />
      </Wrapper>
    ),
  },

  {
    path: "/policies/privacy",
    label: "privacy",
    element: (
      <Wrapper scrollToTop>
        <Privacy />
        <Outlet />
      </Wrapper>
    ),
  },
  {
    path: "/policies/terms",
    label: "terms",
    element: (
      <Wrapper scrollToTop>
        <Outlet />
      </Wrapper>
    ),
  },
  {
    path: "/policies/help-center",
    label: "help-center",
    element: (
      <Wrapper scrollToTop>
        <HelpCenter />
        <Outlet />
      </Wrapper>
    ),
  },
  {
    path: "/blog",
    label: "blog",
    element: (
      <Wrapper scrollToTop>
        <Blog />
        <Outlet />
      </Wrapper>
    ),
  },
  {
    path: "/blog/detail/:route_link?",
    label: "blog",
    element: (
      <Wrapper scrollToTop>
        <BlogDetail />
        <Outlet />
      </Wrapper>
    ),
  },
];

export const routes_v3 = [
  {
    path: "/promotion",
    label: "promotions",
    // hidden: isCredit,
    element: (
      <WrapperV3>
        <ScrollToTop>
          <EventPageV3 />
          <Outlet />
        </ScrollToTop>
      </WrapperV3>
    ),
  },
  {
    path: "/promotion/detail/:uid",
    label: "promotions",
    // hidden: isCredit,
    element: (
      <WrapperV3>
        <ScrollToTop>
          <EventDetail />
          <Outlet />
        </ScrollToTop>
      </WrapperV3>
    ),
  },

  {
    path: "/slots",
    label: "slots",
    element: (
      <WrapperV3>
        <ScrollToTop>
          <SlotV3 gameMainTypeFromProps="slots" />
          <Outlet />
        </ScrollToTop>
      </WrapperV3>
    ),
  },
  {
    path: "/bingo",
    label: "bingo",
    element: (
      <WrapperV3>
        <ScrollToTop>
          <BingoV3 gameMainTypeFromProps="bingo" />
          <Outlet />
        </ScrollToTop>
      </WrapperV3>
    ),
  },
  {
    path: "/fish",
    label: "fish",
    element: (
      <WrapperV3>
        <ScrollToTop>
          <FishV3 gameMainTypeFromProps="fish" />
          <Outlet />
        </ScrollToTop>
      </WrapperV3>
    ),
  },
  {
    path: "/arcade",
    label: "arcade",
    element: (
      <WrapperV3>
        <ScrollToTop>
          <ArcadeV3 gameMainTypeFromProps="arcade" />
          <Outlet />
        </ScrollToTop>
      </WrapperV3>
    ),
  },
  {
    path: "/block-chain",
    label: "block-chain",
    element: (
      <WrapperV3>
        <ScrollToTop>
          <BlockChainV3 gameMainTypeFromProps="block-chain" />
          <Outlet />
        </ScrollToTop>
      </WrapperV3>
    ),
  },
  {
    path: "/lottery",
    label: "lottery",
    element: (
      <WrapperV3>
        <ScrollToTop>
          <LotteryV3 gameMainTypeFromProps="lottery" />
          <Outlet />
        </ScrollToTop>
      </WrapperV3>
    ),
  },
  {
    path: "/live-casino",
    label: "live-casino",
    element: (
      <WrapperV3>
        <ScrollToTop>
          <LiveV3 gameMainTypeFromProps="live-casino" />
          <Outlet />
        </ScrollToTop>
      </WrapperV3>
    ),
  },
  {
    path: "/sports",
    label: "sports",
    element: (
      <WrapperV3>
        <ScrollToTop>
          <SportsV3 gameMainTypeFromProps="sports" />
          <Outlet />
        </ScrollToTop>
      </WrapperV3>
    ),
  },
  {
    path: "/table",
    label: "table",
    element: (
      <WrapperV3>
        <ScrollToTop>
          <TableV3 gameMainTypeFromProps="table" />
          <Outlet />
        </ScrollToTop>
      </WrapperV3>
    ),
  },
  // {
  //   path: "/:gameMainType",
  //   label: "gamePlatform",
  //   element: (
  //     <WrapperV2>
  //       <ScrollToTop>
  //         <GamePlatformPageV2 />
  //         <Outlet />
  //       </ScrollToTop>
  //     </WrapperV2>
  //   ),
  // },
  {
    path: "/:gameMainType/category/:gamePlatform/*",
    label: "gamePlatform",
    element: (
      <WrapperV3>
        <ScrollToTop>
          <GamePlatformPageV3 />
          <Outlet />
        </ScrollToTop>
      </WrapperV3>
    ),
  },
  {
    path: "/:gameMainType/category/:gamePlatform/:subType/*",
    label: "gamePlatform",
    element: (
      <WrapperV3>
        <ScrollToTop>
          <GamePlatformPageV3 />
          <Outlet />
        </ScrollToTop>
      </WrapperV3>
    ),
  },
  {
    path: "/gamebox/:gameName/:to/:gameUid",
    label: "gameBox",
    element: (
      <Authenticator>
        <WrapperV3>
          <ScrollToTop>
            {/* <OverflowHidden closePop> */}
            <GameWrapV3 GameWrapV3>
              <GameBox />
            </GameWrapV3>
            <Outlet />
            {/* </OverflowHidden> */}
          </ScrollToTop>
        </WrapperV3>
      </Authenticator>
    ),
  },
  {
    path: "/*",
    label: "slots",
    element: (
      <WrapperV3>
        <ScrollToTop>
          <SlotV3 gameMainTypeFromProps="slots" />
          <Outlet />
        </ScrollToTop>
      </WrapperV3>
    ),
  },
  {
    path: "*",
    label: "",
    element: <Navigate to="/slots" />,
  },
];

export const routes_v2 = [
  {
    path: "/promotion",
    label: "promotions",
    // hidden: isCredit,
    element: (
      <WrapperV2>
        <ScrollToTop>
          <EventPageV2 />
          <Outlet />
        </ScrollToTop>
      </WrapperV2>
    ),
  },
  {
    path: "/promotion/detail/:uid",
    label: "promotions",
    // hidden: isCredit,
    element: (
      <WrapperV2>
        <ScrollToTop>
          <EventDetail />
          <Outlet />
        </ScrollToTop>
      </WrapperV2>
    ),
  },

  {
    path: "/slots",
    label: "slots",
    element: (
      <WrapperV2>
        <ScrollToTop>
          <SlotV2 gameMainTypeFromProps="slots" />
          <Outlet />
        </ScrollToTop>
      </WrapperV2>
    ),
  },
  {
    path: "/bingo",
    label: "bingo",
    element: (
      <WrapperV2>
        <ScrollToTop>
          <BingoV2 gameMainTypeFromProps="bingo" />
          <Outlet />
        </ScrollToTop>
      </WrapperV2>
    ),
  },
  {
    path: "/fish",
    label: "fish",
    element: (
      <WrapperV2>
        <ScrollToTop>
          <FishV2 gameMainTypeFromProps="fish" />
          <Outlet />
        </ScrollToTop>
      </WrapperV2>
    ),
  },
  {
    path: "/arcade",
    label: "arcade",
    element: (
      <WrapperV2>
        <ScrollToTop>
          <ArcadeV2 gameMainTypeFromProps="arcade" />
          <Outlet />
        </ScrollToTop>
      </WrapperV2>
    ),
  },
  {
    path: "/block-chain",
    label: "block-chain",
    element: (
      <WrapperV2>
        <ScrollToTop>
          <BlockChainV2 gameMainTypeFromProps="block-chain" />
          <Outlet />
        </ScrollToTop>
      </WrapperV2>
    ),
  },
  {
    path: "/lottery",
    label: "lottery",
    element: (
      <WrapperV2>
        <ScrollToTop>
          <LotteryV2 gameMainTypeFromProps="lottery" />
          <Outlet />
        </ScrollToTop>
      </WrapperV2>
    ),
  },
  {
    path: "/live-casino",
    label: "live-casino",
    element: (
      <WrapperV2>
        <ScrollToTop>
          <LiveV2 gameMainTypeFromProps="live-casino" />
          <Outlet />
        </ScrollToTop>
      </WrapperV2>
    ),
  },
  {
    path: "/sports",
    label: "sports",
    element: (
      <WrapperV2>
        <ScrollToTop>
          <SportsV2 gameMainTypeFromProps="sports" />
          <Outlet />
        </ScrollToTop>
      </WrapperV2>
    ),
  },
  {
    path: "/table",
    label: "table",
    element: (
      <WrapperV2>
        <ScrollToTop>
          <TableV2 gameMainTypeFromProps="table" />
          <Outlet />
        </ScrollToTop>
      </WrapperV2>
    ),
  },
  // {
  //   path: "/:gameMainType",
  //   label: "gamePlatform",
  //   element: (
  //     <WrapperV2>
  //       <ScrollToTop>
  //         <GamePlatformPageV2 />
  //         <Outlet />
  //       </ScrollToTop>
  //     </WrapperV2>
  //   ),
  // },
  {
    path: "/:gameMainType/category/:gamePlatform/*",
    label: "gamePlatform",
    element: (
      <WrapperV2>
        <ScrollToTop>
          <GamePlatformPageV2 />
          <Outlet />
        </ScrollToTop>
      </WrapperV2>
    ),
  },
  {
    path: "/:gameMainType/category/:gamePlatform/:subType/*",
    label: "gamePlatform",
    element: (
      <WrapperV2>
        <ScrollToTop>
          <GamePlatformPageV2 />
          <Outlet />
        </ScrollToTop>
      </WrapperV2>
    ),
  },
  {
    path: "/gamebox/:gameName/:to/:gameUid",
    label: "gameBox",
    element: (
      <Authenticator>
        <WrapperV2>
          <ScrollToTop>
            {/* <OverflowHidden closePop> */}
            <GameWrapV2>
              <GameBox />
            </GameWrapV2>
            <Outlet />
            {/* </OverflowHidden> */}
          </ScrollToTop>
        </WrapperV2>
      </Authenticator>
    ),
  },
  {
    path: "/*",
    label: "slots",
    element: (
      <WrapperV2>
        <ScrollToTop>
          <SlotV2 gameMainTypeFromProps="slots" />
          <Outlet />
        </ScrollToTop>
      </WrapperV2>
    ),
  },
  {
    path: "*",
    label: "",
    element: <Navigate to="/slots" />,
  },
];

export const routes = [
  {
    path: "/*",
    label: "home",
    element: isCredit ? (
      <Navigate to="/casino" />
    ) : (
      <Wrapper>
        <ScrollToTop>
          <Home />
          <Outlet />
        </ScrollToTop>
      </Wrapper>
    ),
  },
  {
    path: "*",
    label: "",
    element: <Navigate to="/" />,
  },
  {
    path: "/promotion",
    label: "promotions",
    // hidden: isCredit,
    element: (
      <Wrapper>
        <EventPage />
        <Outlet />
      </Wrapper>
    ),
  },
  {
    path: "/promotion/detail/:uid",
    label: "promotions",
    // hidden: isCredit,
    element: (
      <Wrapper>
        <ScrollToTop>
          <EventDetail />
          <Outlet />
        </ScrollToTop>
      </Wrapper>
    ),
  },

  {
    path: "/casino",
    label: "casino",
    element: (
      <Wrapper>
        <ScrollToTop>
          <Slot />
          <Outlet />
        </ScrollToTop>
      </Wrapper>
    ),
  },
  {
    path: "/live-casino",
    label: "live",
    element: (
      <Wrapper>
        <ScrollToTop>
          <Live />
          <Outlet />
        </ScrollToTop>
      </Wrapper>
    ),
  },
  {
    path: "/sports",
    label: "sports",
    element: (
      <Wrapper>
        <ScrollToTop>
          <Sports />
          <Outlet />
        </ScrollToTop>
      </Wrapper>
    ),
  },
  {
    path: "/lottery",
    label: "lottery",
    element: (
      <Wrapper>
        <ScrollToTop>
          <Lottery />
          <Outlet />
        </ScrollToTop>
      </Wrapper>
    ),
  },
  {
    path: "/bingo",
    label: "bingo",
    element: (
      <Wrapper>
        <ScrollToTop>
          <Bingo />
          <Outlet />
        </ScrollToTop>
      </Wrapper>
    ),
  },
  {
    path: "/:casinoType?/category/:gamePlatform/*",
    label: "gamePlatform",
    element: (
      <Wrapper scrollToTop>
        <GamePlatformPage />
        <Outlet />
      </Wrapper>
    ),
  },

  {
    path: "/:casinoType?/category/:gamePlatform/AllGames",
    label: "gamePlatform",
    element: (
      <Wrapper scrollToTop>
        <GamePlatformPage />
        <Outlet />
      </Wrapper>
    ),
  },
  {
    path: "/:gameType/category/:gamePlatform/:subType/*",
    label: "gameSubType",
    element: (
      <Wrapper scrollToTop>
        <GameSubTypeList />
        <Outlet />
      </Wrapper>
    ),
  },
  {
    path: "/gamebox/:gameName/:to/:gameUid",
    label: "gameBox",
    element: (
      <Authenticator>
        <Wrapper>
          <ScrollToTop>
            {/* <OverflowHidden closePop> */}
            <GameWrap>
              <GameBox />
            </GameWrap>
            <Outlet />
            {/* </OverflowHidden> */}
          </ScrollToTop>
        </Wrapper>
      </Authenticator>
    ),
  },
];
