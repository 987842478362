import {
  Avatar,
  Box,
  Divider,
  Flex,
  Icon,
  Image,
  Progress,
  Text,
  WrapItem,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import CopyIcon from "../../../components/CopyComponent/CopyIcon";
import MyBalance from "../../../utils/myBalance";
import Balance from "../../Profile/components/balance";
import { HiChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { COMMON_WEB_PATH } from "../../../constant";
import useVipInfo from "../../../hook/useVipInfo";
import useNextVipLevelConfig from "../../../hook/useNextVipLevelConfig";
import { formatNumber } from "../../../utils/formatNumber";
import { useTranslation } from "react-i18next";
import { IoIosArrowRoundForward } from "react-icons/io";
import useCurrentVipLevelConfig from "../../../hook/useCurrentVipLevelConfig";
import CreditPage from "../../../utils/credit/credit_page";
import VipOpenPage from "../../../utils/credit/vip_open_page";

const ProfileCardV2 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`profile.${key}`);

  const cardBg = useColorModeValue("light.100", "dark.100");
  const iconBg = useColorModeValue("brand.500", "brand.500");
  const textColor = useColorModeValue("dark.100", "light.100");

  const memberData = useSelector((state) => state.isMemberInfo);
  const { name, uid, memId } = memberData || {};

  const { current_wager, current_level, current_symbol } = useVipInfo();

  const current_vip_detail = useCurrentVipLevelConfig({
    currentLevel: current_level,
  });

  const { next_level_criteria_data, ...others } = useNextVipLevelConfig({
    level: current_level + 1,
  });

  const wager_percent = useMemo(() => {
    const result =
      Number(current_wager / next_level_criteria_data?.criteria_amount) * 100;
    if (result > 100) return 100;
    return formatNumber(result, { ignore_crypto: true });
  }, [current_wager, next_level_criteria_data?.criteria_amount]);

  return (
    <Flex
      direction={`column`}
      gap="5px"
      as="section"
      // bg={cardBg}
      borderRadius={`common`}
      p="10px"
    >
      <Flex gap="10px" justifyContent={`space-between`}>
        <Flex maxW="60%" gap="10px">
          <WrapItem p="3px" bg={iconBg} borderRadius={`full`}>
            <Avatar
              name="Header Icon"
              src={`${COMMON_WEB_PATH}/ui-image/user-icon-1.avif`}
            />
          </WrapItem>
          <Flex direction={`column`} py="3px" justifyContent={`space-between`}>
            <Flex gap="3px" alignItems={`center`}>
              <Text
                color={textColor}
                fontWeight={`bold`}
                fontSize="md"
                maxW={{
                  md: "115px",
                  base: "150px",
                }}
                className="text-overflow"
                as="p"
                my="0"
              >
                {name ? (
                  name
                ) : (
                  <Link to="../profile/settings/personal-data">
                    ({i18n("go_to_set_nick_name")})
                  </Link>
                )}
              </Text>
              {/* <CopyIcon copyLink={name} /> */}
              <Flex gap="3px" alignItems={`center`}>
                <Text
                  color={`special.1`}
                  fontSize="10px"
                  fontWeight={`semibold`}
                  as="p"
                  my="0"
                >
                  ID:{uid}
                </Text>
                <CopyIcon copyLink={uid} />
              </Flex>
            </Flex>
            <Flex gap="3px" alignItems={`center`}>
              <Flex alignItems={"center"}>
                <Text
                  color={`special.1`}
                  fontSize="10px"
                  fontWeight={`semibold`}
                  as="p"
                  my="0"
                >
                  MEMID:
                </Text>
                <Text
                  color={`special.1`}
                  fontSize="10px"
                  fontWeight={`semibold`}
                  as="p"
                  my="0"
                  maxW="100px"
                  className="text-overflow"
                >
                  {memId}
                </Text>
              </Flex>
              <CopyIcon fontSize="10px" copyLink={memId} />
            </Flex>
          </Flex>
        </Flex>
        <Flex alignItems={`center`} gap="5px">
          {/* <Flex
            direction={`column`}
            justifyContent={`center`}
            alignItems={`center`}
            gap="10px"
          >
            <Flex
              gap="3px"
              direction={`column`}
              justifyContent={`center`}
              alignItems={`flex-end`}
            >
              <Text fontSize="xs" color={"special.1"}>
                {i18n("Balance")}
              </Text>
              <Balance
                balanceStyle={{
                  color: textColor,
                }}
              />
            </Flex>
          </Flex> */}
          <Link to="data">
            <Icon as={HiChevronRight} fontSize="xl" color="special.1" />
          </Link>
        </Flex>
      </Flex>
      <CreditPage>
        <VipOpenPage>
          <Divider mb="5px" />
          <Flex
            gap="10px"
            justifyContent={`space-between`}
            alignItems={`center`}
          >
            <Image
              src={`${COMMON_WEB_PATH}/vip/vip${current_level}_l.avif`}
              w="35px"
              objectFit={`cover`}
            />
            <Flex gap="10px" justifyContent={`space-between`} w="100%">
              <Flex direction={`column`} justifyContent={`center`}>
                <Text color={textColor} fontWeight="bold" fontSize="md"></Text>
              </Flex>
              <Flex
                direction={`column`}
                gap="3px"
                w="100%"
                alignItems={`center`}
                justifyContent={`space-between`}
              >
                {/* <Text fontSize="xs" fontWeight={`semibold`} color={textColor}>
              {formatNumber(current_wager)}
              XP
            </Text> */}
                <Flex
                  w="100%"
                  alignItems={`center`}
                  justifyContent={`space-between`}
                >
                  <Flex
                    fontSize="10px"
                    fontWeight={`bold`}
                    gap="2px"
                    alignItems={`center`}
                    color="special.1"
                  >
                    <Text>{i18n("your_vip_progress")}</Text>
                    <Icon as={IoIosArrowRoundForward} />
                  </Flex>
                  <Text fontSize="10px" color="special.1" fontWeight={`bold`}>
                    {wager_percent}%
                  </Text>
                </Flex>
                <Progress
                  w="100%"
                  value={wager_percent}
                  size="xs"
                  variant={`brandPrimary`}
                />
                {/* <Text fontSize="xs" color={`special.1`}>
              {formatNumber(next_level_criteria_data?.criteria_amount)} XP
            </Text> */}
                <Flex
                  w="100%"
                  alignItems={`center`}
                  justifyContent={`space-between`}
                >
                  <Text fontSize="10px" fontWeight={`bold`} color="special.1">
                    {current_vip_detail?.name}
                  </Text>
                  <Text fontSize="10px" color="special.1" fontWeight={`bold`}>
                    {others?.name}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Link to="vip">
              <Icon as={HiChevronRight} color="special.1" />
            </Link>
          </Flex>
        </VipOpenPage>
      </CreditPage>
    </Flex>
  );
};

export default ProfileCardV2;
