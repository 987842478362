import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopTab from "../Home/components/topTab";
import { Link } from "react-router-dom";
import LazyImage from "../../components/Loading/lazyImage";
import i18next, { use } from "i18next";
import useDeviceType from "../../hook/useDeviceType";
import Empty from "../../components/Empty/Empty";
import Seo from "./seo";
import NotFound from "../../components/ProgressBlock/NotFound";
import useProcessEventList from "../../hook/useProcessEventList";
import { useTranslation } from "react-i18next";
import {
  Box,
  Flex,
  Grid,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { DATA2_PATH } from "../../constant";
import { languageMapping } from "../../i18n/config";
import GetEventImagePath from "../../hook/useEventImagePath";
import { MdOpenInNew } from "react-icons/md";
import { openEventPop } from "../../redux/action/member/memberAction";

const EventPage = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`promotions.${key}`);
  const eventList = useSelector((state) => state.isEventList);
  const dispatch = useDispatch();
  const isMobile = useDeviceType() === "Mobile";

  const textColor = useColorModeValue("dark.100", "light.100");
  const bgColor = useColorModeValue("light.200", "dark.100");

  const processEventList = useProcessEventList({ event_list: eventList });
  const filter_event_list = useMemo(() => {
    return processEventList?.filter((item) => item?.copywriting_is_active == 1);
  }, [processEventList]);

  const handleOpenEventPop = () => {
    dispatch(openEventPop());
  };

  return (
    <Box as="main">
      <Seo />
      <Box
        as="section"
        display={{
          base: "block",
          md: "none",
        }}
      >
        <TopTab />
      </Box>
      <Box as="section" px="common-x" py="common-y">
        <Flex gap="10px" alignItems={"center"}>
          <Text
            as="h4"
            fontSize="2xl"
            color={textColor}
            fontWeight={`semibold`}
          >
            {i18n("title")}
          </Text>
          <Icon
            as={MdOpenInNew}
            cursor={"pointer"}
            fontSize="xl"
            color="brand.500"
            onClick={handleOpenEventPop}
          />
        </Flex>

        {filter_event_list.length === 0 ? (
          <NotFound />
        ) : (
          <Grid
            as="section"
            mt="16px"
            templateColumns={{
              base: "repeat(1, minmax(0, 1fr))",
              md: "repeat(2, minmax(0, 1fr))",
              "2xl": "repeat(3, minmax(0, 1fr))",
            }}
            gap="20px"
            w="100%"
          >
            {filter_event_list?.map((item, index) => {
              const { reward_title, reward_sub_title, uid } = item || {};
              const lang = languageMapping(i18next.language);
              return (
                <Link to={`/promotion/detail/${uid}`} key={index}>
                  <Box
                    as="section"
                    w="100%"
                    borderRadius={`common`}
                    role="group"
                    overflow="hidden"
                    h="300px"
                    position={`relative`}
                    mb="15px"
                    cursor="pointer"
                  >
                    <Box w="100%" h="200px" overflow="hidden">
                      <LazyImage
                        className="object-cover h-full w-full group-hover:scale-[1.15] group-hover:-rotate-3 transition "
                        onError={({ currentTarget }) => {
                          if (!currentTarget.src.includes("fallback")) {
                            currentTarget.onerror = null;
                            currentTarget.src = `${DATA2_PATH}/event/${uid}/en_US/${uid}_en_US_mobile_image.png`;
                          }
                        }}
                        src={`${DATA2_PATH}/event/${uid}/${lang}/${uid}_${lang}_mobile_image.png`}
                      />
                    </Box>
                    <Box
                      bg={bgColor}
                      h="100px"
                      display="flex"
                      flexDirection="column"
                      gap="10px"
                      p="10px"
                    >
                      <Text
                        as="h4"
                        fontWeight={`semibold`}
                        fontSize="lg"
                        _groupHover={{
                          color: "brand.500",
                        }}
                        color={textColor}
                      >
                        {reward_title}
                      </Text>
                      <Text
                        as="h5"
                        fontWeight={`medium`}
                        fontSize="sm"
                        color={textColor}
                        className="text-overflow"
                      >
                        {reward_sub_title}
                      </Text>
                    </Box>
                  </Box>
                  {/* <img
              className="w-[150px] h-[160px] absolute top-0 right-0 object-cover"
              src={item}
            /> */}
                </Link>
              );
            })}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default EventPage;
