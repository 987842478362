import {
  Box,
  Flex,
  Image,
  Text,
  useColorMode,
  Icon,
  chakra,
  shouldForwardProp,
  Divider,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { stylesConfig } from "../../helpers/stylesConfig";
import { COMMON_ALT } from "../../constant";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import { MdEventNote, MdOutlineLanguage } from "react-icons/md";
import { AnimatePresence, isValidMotionProp, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { CloseMenu, controlSubMenu } from "../../redux/action/action";
import useDeviceType from "../../hook/useDeviceType";
import { RiHomeSmileFill, RiMenuUnfoldLine } from "react-icons/ri";
import rouletteImage from "../../images/EventListSelect/active_04.webp";
import checkInImage from "../../images/EventListSelect/active_07.webp";
import useSubTypeList from "../../hook/useSubTypeList";
import { menuIcon } from "./helper/menuIcon";
import useCasinoType from "../../hook/useCasinoType";
import ProfileButton from "../../pages/PROFILE_SETTING/components/profileButton";
import CustomerNameText from "../../pages/PROFILE_SETTING/components/customerNameText";
import Balance from "../../pages/Profile/components/balance";
import { waitTime } from "../../utils/waitTime";
import {
  FaCalendarAlt,
  FaHeadphonesAlt,
  FaMobileAlt,
  FaNewspaper,
} from "react-icons/fa";
import {
  openChat,
  openLanguagePop,
} from "../../redux/action/member/memberAction";
import { headerMenu } from "./helper/headerMenu";
import useProcessEventList from "../../hook/useProcessEventList";
import ProfileCardNotLogin from "../../pages/PROFILE_SETTING/components/profileCardNotLogin";
import useCheckLogin from "../../hook/useCheckLogin";
import CreditPage from "../../utils/credit/credit_page";
import useAppDownloadLink from "../../pages/App/hooks/useAppDownloadLink";
import AppWrapper from "../../pages/App/AppWrapper";

const isCredit = process.env.REACT_APP_CREDIT == 1;

const otherMenu = [
  {
    text: "CUSTOMER SERVICE",
    link: "customer-service",
    icon: FaHeadphonesAlt,
    hidden: isCredit,
    iconClassName: {
      color: "brand.500",
    },
  },
  {
    text: "LANGUAGE",
    link: "language",
    icon: MdOutlineLanguage,
  },
  {
    text: "BLOG",
    link: "blog",
    hidden: isCredit,
    icon: FaNewspaper,
  },
];

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const menuSubVariants = {
  open: {
    height: "auto",
  },
  close: {
    height: "0",
  },
};

const Menu = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const i18n_header = (key) => t(`header.${key}`);

  const textColor = useColorModeValue("light.100", "light.100");
  const menuBg = useColorModeValue("brand.500", "dark.100");
  const textColorHover = useColorModeValue("dark.600", "brand.500");
  const logoBg = useColorModeValue("light.100", "dark.100");

  const { isLogin } = useCheckLogin();

  const app_download_link = useAppDownloadLink();

  const { gamePlatform } = useParams();

  const casinoType = useCasinoType();

  const deviceType = useDeviceType();

  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const getCurrentPath = useMemo(() => {
    const result = pathname.split("/");
    return result?.[1];
  }, [pathname]);

  const getSubTypePath = useMemo(() => {
    const result = pathname.split("/");
    return result;
  }, [pathname]);

  const animationProps = useMemo(() => {
    if (deviceType) {
      return deviceType === "PC"
        ? {
            initial: { x: "0%" },
            animate: { x: "0%" },
            exit: { x: "0%" },
            transition: { duration: 0.15, ease: "easeInOut" },
          }
        : {
            initial: { x: "-100%" },
            animate: { x: "0%" },
            exit: { x: "-100%" },
            transition: { duration: 0.15, ease: "easeInOut" },
          };
    }
  }, [deviceType]);

  const dispatch = useDispatch();
  const isStickyLeft = useSelector((state) => state.isStickyLeft);
  const subMenuControl = useSelector((state) => state.isSubMenuControl);
  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const eventList = useSelector((state) => state.isEventList);

  const processEventList = useProcessEventList({ event_list: eventList });

  const { game_subtype } = isHandleAllGame;
  const getSubType = useCallback(
    (casinoType) => {
      if (casinoType === "promotion") {
        return processEventList?.length === 0
          ? []
          : processEventList
              ?.filter((item) => item?.is_show_carousel == 1)
              ?.filter((item) => item?.copywriting_is_active == 1)
              ?.map((item) => {
                return {
                  label: item?.reward_title || "",
                  uid: item?.uid || "",
                };
              });
      }
      if (game_subtype) {
        const sub = game_subtype
          ?.filter((item) => {
            if (item.belong_type === null) {
              return item;
            } else if (casinoType === "casino") {
              return item.belong_type?.toLowerCase() === "slots";
            } else if (casinoType === "live-casino") {
              return item.belong_type?.toLowerCase() === "live-casino";
            } else if (casinoType === "sports") {
              return item.belong_type?.toLowerCase() === "sports";
            } else if (casinoType === "lottery") {
              return item.belong_type?.toLowerCase() === "lottery";
            } else if (casinoType === "bingo") {
              return item.belong_type?.toLowerCase() === "bingo";
            } else {
              return item;
            }
          })
          ?.map((item) => item.game_subtype);

        return sub.length === 0 ? [] : ["MyFavorite", ...sub?.reverse()];
      }
    },
    [game_subtype, casinoType, processEventList]
  );

  return (
    <ChakraBox
      h={`100%`}
      position={isStickyLeft ? "fixed" : "relative"}
      left="0px"
      pb={{ base: "0px" }}
      {...animationProps}
    >
      <Flex
        w={{
          base: "280px",
          md: "100%",
          lg: "100%",
        }}
        minH="100%"
        maxH="100vh"
        bg={menuBg}
        direction={`column`}
        alignItems={`center`}
        gap={{
          base: "10px",
          lg: "25px",
        }}
        pb={{
          base: "0px",
          lg: "20px",
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        overflowY="scroll"
        overflowX="hidden"
        className="no-scrollbar"
        position="relative"
      >
        <Box
          mb={{
            base: "0px",
            lg: "0px",
          }}
          minH={{
            base: "0px",
            md: "100px",
          }}
          maxH="100px"
          bg={logoBg}
          w="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            display={{
              base: "none",
              md: "block",
            }}
          >
            <Link to="/">
              <Image
                src={stylesConfig.mainLogo}
                cursor={"pointer"}
                display={{
                  base: "none",
                  md: "block",
                }}
                width={{
                  md: "150px",
                  base: "65px",
                }}
                pointerEvents={"all"}
                alt={COMMON_ALT}
              />
            </Link>
          </Box>
        </Box>
        {isLogin ? (
          <>
            <Box
              display={{
                base: "flex",
                lg: "none",
              }}
              w="100%"
              pl="10px"
              gap="10px"
            >
              <ProfileButton
                path="profile/"
                className="bg-base-light-white text-base-dark dark:bg-base-input-gray dark:text-base-white"
              />
              <Flex direction={`column`} justifyContent={`space-between`}>
                <CustomerNameText className="font-medium tracking-tighter text-light-text !text-base" />
                <Balance />
              </Flex>
            </Box>
            <Divider
              display={{
                base: "block",
                lg: "none",
              }}
              my="5px"
              px="10px"
            />
          </>
        ) : (
          <Box
            display={{
              base: "block",
              lg: "none",
            }}
          >
            <ProfileCardNotLogin />
          </Box>
        )}

        <Flex w="100%" direction={`column`} gap="5px">
          <Flex direction={`column`} gap="12px">
            <Box
              role="group"
              w="100%"
              pl={{
                base: "20px",
                md: "35px",
              }}
              py="5px"
            >
              {!isCredit && (
                <Link
                  to={`/`}
                  onClick={async () => {
                    await waitTime(100);
                    dispatch(CloseMenu());
                  }}
                >
                  <Flex
                    alignItems={`center`}
                    gap="10px"
                    _hover={{
                      color: textColorHover,
                    }}
                    cursor="pointer"
                    mr="20px"
                    _groupHover={{
                      color: textColorHover,
                    }}
                    color={getCurrentPath === "" ? textColorHover : textColor}
                  >
                    <Icon as={RiHomeSmileFill} size="large" fontSize="25px" />
                    <Text
                      as="h5"
                      fontSize="15px"
                      my="0px"
                      fontWeight={`medium`}
                    >
                      {i18n(`home`)}
                    </Text>
                  </Flex>
                </Link>
              )}
            </Box>
            <Box
              role="group"
              w="100%"
              pl={{
                base: "20px",
                md: "35px",
              }}
              py="5px"
            >
              <Link
                to={`casino-group`}
                onClick={async () => {
                  await waitTime(100);
                  dispatch(CloseMenu());
                }}
              >
                <Flex
                  alignItems={`center`}
                  gap="10px"
                  _hover={{
                    color: textColorHover,
                  }}
                  cursor="pointer"
                  mr="20px"
                  _groupHover={{
                    color: textColorHover,
                  }}
                  color={textColor}
                >
                  <Icon as={RiMenuUnfoldLine} size="large" fontSize="25px" />
                  <Text as="h5" fontSize="15px" my="0px" fontWeight={`medium`}>
                    {gamePlatform && gamePlatform !== "all"
                      ? gamePlatform
                      : i18n_header("All providers")}
                  </Text>
                </Flex>
              </Link>
            </Box>
          </Flex>
          <Divider my="10px" px="10px" />
          <Flex direction={`column`} gap="12px">
            {window.roulettes_activity == 1 ? (
              <Flex direction={`column`} gap="12px">
                <Box
                  role="group"
                  w="100%"
                  pl={{
                    base: "20px",
                    md: "35px",
                  }}
                  py="5px"
                >
                  <Link
                    to={"n-roulette"}
                    onClick={async () => {
                      await waitTime(100);
                      dispatch(CloseMenu());
                    }}
                  >
                    <Flex
                      alignItems={`center`}
                      gap="10px"
                      _hover={{
                        color: textColorHover,
                      }}
                      cursor="pointer"
                      mr="20px"
                      _groupHover={{
                        color: textColorHover,
                      }}
                      color={textColor}
                    >
                      <Image
                        className="animate-spin"
                        src={rouletteImage}
                        w="25px"
                        h="25px"
                      />
                      <Text
                        as="h5"
                        fontSize="15px"
                        my="0px"
                        fontWeight={`medium`}
                      >
                        {i18n("LuckyRoulette")}
                      </Text>
                    </Flex>
                  </Link>
                </Box>
              </Flex>
            ) : null}
            {window.check_in_activity == 1 ? (
              <Flex direction={`column`} gap="5px">
                <Box
                  role="group"
                  w="100%"
                  pl={{
                    base: "20px",
                    md: "35px",
                  }}
                  py="5px"
                >
                  <Link
                    to={"checkin"}
                    onClick={async () => {
                      await waitTime(100);
                      dispatch(CloseMenu());
                    }}
                  >
                    <Flex
                      alignItems={`center`}
                      gap="10px"
                      _hover={{
                        color: textColorHover,
                      }}
                      cursor="pointer"
                      mr="20px"
                      _groupHover={{
                        color: textColorHover,
                      }}
                      color={textColor}
                    >
                      <Image src={checkInImage} w="25px" h="25px" />
                      <Text
                        as="h5"
                        fontSize="15px"
                        my="0px"
                        fontWeight={`medium`}
                      >
                        {i18n("check-in")}
                      </Text>
                    </Flex>
                  </Link>
                </Box>
              </Flex>
            ) : null}
          </Flex>
          <Divider my="10px" px="10px" />

          <Flex direction={`column`} gap="12px">
            {headerMenu
              ?.filter((item) => !item.hidden)
              ?.map((item) => {
                const subMenu = getSubType(item.link);
                return subMenu?.length == 0 &&
                  item?.link !== "promotion" ? null : (
                  <Flex direction={`column`} gap="5px">
                    <Box
                      role="group"
                      w="100%"
                      pl={{
                        base: "20px",
                        md: "35px",
                      }}
                      pr={{
                        base: "20px",
                        lg: "10px",
                      }}
                      py="5px"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box
                        onClick={async () => {
                          await navigate(`/${item.link}`);
                          await waitTime(100);
                          dispatch(CloseMenu());
                        }}
                        key={item.text}
                      >
                        <Flex
                          alignItems={`center`}
                          gap="10px"
                          w="100%"
                          _hover={{
                            color: textColorHover,
                          }}
                          cursor="pointer"
                          mr="20px"
                          _groupHover={{
                            color: textColorHover,
                          }}
                          color={
                            getCurrentPath === item.link
                              ? textColorHover
                              : textColor
                          }
                        >
                          <Icon as={item.icon} size="large" fontSize="25px" />
                          <Text
                            as="h5"
                            fontSize="15px"
                            my="0px"
                            fontWeight={`medium`}
                          >
                            {i18n(item.text.toLowerCase())}
                          </Text>
                        </Flex>
                      </Box>
                      {subMenu?.length > 0 && (
                        <Icon
                          onClick={() => {
                            dispatch(controlSubMenu(item.link));
                          }}
                          cursor={"pointer"}
                          color={textColor}
                          className={`transition duration-300 animate-rotate ${
                            subMenuControl?.[item.link] ? "rotate-180" : ""
                          }`}
                          as={IoIosArrowDown}
                        />
                      )}
                    </Box>
                    {/* {getCurrentPath === item.link && ( */}
                    {/* {categoryOpen?.[item.link] && ( */}
                    <ChakraBox
                      initial={false}
                      overflow="hidden"
                      animate={
                        subMenuControl?.[item.link]
                          ? menuSubVariants.open
                          : menuSubVariants.close
                      }
                    >
                      <Flex
                        position="relative"
                        direction={`column`}
                        gap="12px"
                        pl="55px"
                      >
                        {subMenu?.map((subItem) => {
                          const currentPath = getSubTypePath?.some((path) => {
                            if (item?.text === "PROMOTION") {
                              return path == subItem?.uid;
                            } else {
                              return path == subItem;
                            }
                          });
                          const link =
                            item?.text === "PROMOTION"
                              ? `/promotion/detail/${subItem?.uid}`
                              : `/${item.link}/category/${"all"}/${subItem}`;
                          return (
                            <Box role="group">
                              <Link
                                to={link}
                                onClick={async () => {
                                  await waitTime(100);
                                  dispatch(CloseMenu());
                                }}
                              >
                                <Flex
                                  alignItems={`center`}
                                  gap="5px"
                                  _groupHover={{
                                    color: textColorHover,
                                  }}
                                  color={
                                    currentPath ? textColorHover : textColor
                                  }
                                >
                                  <Icon
                                    as={menuIcon(subItem, "icon")}
                                    fontSize="15px"
                                  />
                                  <Text as="h5" my="0px" fontSize="13px">
                                    {item?.text === "PROMOTION"
                                      ? subItem?.label
                                      : i18n(subItem)}
                                  </Text>
                                </Flex>
                              </Link>
                            </Box>
                          );
                        })}
                      </Flex>
                    </ChakraBox>
                  </Flex>
                );
              })}
          </Flex>
          <Divider my="10px" px="10px" />
          <AppWrapper show>
            <Flex direction={`column`} gap="5px">
              <Box
                role="group"
                w="100%"
                pl={{
                  base: "20px",
                  md: "35px",
                }}
                py="5px"
              >
                <Box as={Link} to={"app"}>
                  <Flex
                    alignItems={`center`}
                    gap="10px"
                    _hover={{
                      color: textColorHover,
                    }}
                    cursor="pointer"
                    mr="20px"
                    _groupHover={{
                      color: textColorHover,
                    }}
                    color={textColor}
                  >
                    <Icon as={FaMobileAlt} size="large" fontSize="25px" />
                    <Text
                      as="h5"
                      fontSize="15px"
                      my="0px"
                      fontWeight={`medium`}
                    >
                      {i18n("download-app")}
                    </Text>
                  </Flex>
                </Box>
              </Box>
            </Flex>
            <Divider my="10px" px="10px" />
          </AppWrapper>

          <Flex direction={`column`} gap="12px">
            {otherMenu
              ?.filter((item) => !item.hidden)
              ?.map((item) => {
                return (
                  <Box
                    role="group"
                    w="100%"
                    pl={{
                      base: "20px",
                      md: "35px",
                    }}
                    pr={{
                      base: "20px",
                      lg: "10px",
                    }}
                    py="5px"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box
                      onClick={async () => {
                        if (item.text === "CUSTOMER SERVICE") {
                          await dispatch(openChat());
                        } else if (item.text === "LANGUAGE") {
                          await dispatch(openLanguagePop());
                        } else {
                          navigate(`/${item.link}`);
                          await waitTime(100);
                          dispatch(CloseMenu());
                        }
                      }}
                      key={item.text}
                    >
                      <Flex
                        alignItems={`center`}
                        gap="10px"
                        w="100%"
                        _hover={{
                          color: textColorHover,
                        }}
                        cursor="pointer"
                        mr="20px"
                        _groupHover={{
                          color: textColorHover,
                        }}
                        color={
                          getCurrentPath === item.link
                            ? textColorHover
                            : textColor
                        }
                      >
                        <Icon
                          as={item.icon}
                          color={textColor}
                          size="large"
                          fontSize="25px"
                        />
                        <Text
                          as="h5"
                          fontSize="15px"
                          my="0px"
                          fontWeight={`medium`}
                        >
                          {i18n(item.text.toLowerCase())}
                        </Text>
                      </Flex>
                    </Box>
                  </Box>
                );
              })}
          </Flex>
        </Flex>
        <Icon
          as={IoMdClose}
          display={{
            base: "block",
            md: "none",
          }}
          position="absolute"
          top="5px"
          right="5px"
          fontSize="3xl"
          mt="0px"
          color="brand.500"
          cursor="pointer"
          onClick={() => dispatch(CloseMenu())}
        />
      </Flex>
    </ChakraBox>
  );
};

export default Menu;
