import React, { useMemo, useState } from "react";

const VipOpenPage = ({ children, revert }) => {
  const isVipOpen = window.vip_open == 1;

  const show = useMemo(() => {
    if (revert) {
      return !isVipOpen;
    }
    return isVipOpen;
  }, [revert, isVipOpen]);

  return show ? children : <></>;
};

export default VipOpenPage;
