import {
  Box,
  Flex,
  Image,
  Text,
  useColorMode,
  Icon,
  chakra,
  shouldForwardProp,
  Divider,
  useColorModeValue,
  Grid,
  GridItem,
  Link as ChakraLink,
} from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { stylesConfig } from "../../helpers/stylesConfig";
import { COMMON_ALT } from "../../constant";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import { MdEventNote, MdOutlineLanguage } from "react-icons/md";
import { AnimatePresence, isValidMotionProp, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { CloseMenu, controlSubMenu } from "../../redux/action/action";
import useDeviceType from "../../hook/useDeviceType";
import { RiHomeSmileFill, RiMenuUnfoldLine } from "react-icons/ri";
import rouletteImage from "../../images/EventListSelect/active_04.webp";
import useSubTypeList from "../../hook/useSubTypeList";
import { menuIcon } from "./helper/menuIcon";
import useCasinoType from "../../hook/useCasinoType";
import ProfileButton from "../../pages/PROFILE_SETTING/components/profileButton";
import CustomerNameText from "../../pages/PROFILE_SETTING/components/customerNameText";
import Balance from "../../pages/Profile/components/balance";
import { waitTime } from "../../utils/waitTime";
import {
  FaCalendarAlt,
  FaHeadphonesAlt,
  FaMobileAlt,
  FaNewspaper,
} from "react-icons/fa";
import {
  openChat,
  openLanguagePop,
} from "../../redux/action/member/memberAction";
import { headerMenu } from "./helper/headerMenu";
import useProcessEventList from "../../hook/useProcessEventList";
import ProfileCardNotLogin from "../../pages/PROFILE_SETTING/components/profileCardNotLogin";
import useCheckLogin from "../../hook/useCheckLogin";
import CreditPage from "../../utils/credit/credit_page";
import { routeMapping } from "../v2Layout/GameTypeSelector/utils/routeMapping";
import { menuIconV2 } from "./helper/menuIcon_v2";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import i18next from "i18next";
import { exceptGameType } from "../v3Layout/GameTypeSelector/utils/exceptGameType";
import AppWrapper from "../../pages/App/AppWrapper";

const isCredit = process.env.REACT_APP_CREDIT == 1;

const otherMenu = [
  {
    text: "CUSTOMER SERVICE",
    link: "customer-service",
    icon: FaHeadphonesAlt,
    hidden: isCredit,
    iconClassName: {
      color: "brand.500",
    },
  },
  {
    text: "LANGUAGE",
    link: "language",
    icon: MdOutlineLanguage,
  },
  {
    text: "BLOG",
    link: "blog",
    hidden: isCredit,
    icon: FaNewspaper,
  },
];

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const menuSubVariants = {
  open: {
    height: "auto",
  },
  close: {
    height: "0",
  },
};

const MenuV2 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const i18n_header = (key) => t(`header.${key}`);

  const textColor = useColorModeValue("light.100", "special.1");
  const menuBg = useColorModeValue("brand.500", "dark.200");
  const textColorHover = useColorModeValue("dark.600", "brand.500");
  const logoBg = useColorModeValue("light.100", "dark.100");

  const { isLogin } = useCheckLogin();

  const { gamePlatform } = useParams();

  const casinoType = useCasinoType();

  const deviceType = useDeviceType();

  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const getCurrentPath = useMemo(() => {
    const result = pathname.split("/");
    return result?.[1];
  }, [pathname]);

  const getSubTypePath = useMemo(() => {
    const result = pathname.split("/");
    return result;
  }, [pathname]);

  const animationProps = useMemo(() => {
    if (deviceType) {
      return deviceType === "PC"
        ? {
            initial: { x: "0%" },
            animate: { x: "0%" },
            exit: { x: "0%" },
            transition: { duration: 0.15, ease: "easeInOut" },
          }
        : {
            initial: { x: "-100%" },
            animate: { x: "0%" },
            exit: { x: "-100%" },
            transition: { duration: 0.15, ease: "easeInOut" },
          };
    }
  }, [deviceType]);

  const dispatch = useDispatch();
  const isStickyLeft = useSelector((state) => state.isStickyLeft);
  const subMenuControl = useSelector((state) => state.isSubMenuControl);
  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const eventList = useSelector((state) => state.isEventList);
  const processEventList = useProcessEventList({ event_list: eventList });

  const { game_subtype, gameListByTypeAndSubType, gameType, gameList } =
    isHandleAllGame;
  const getSubType = useCallback(
    (casinoType) => {
      if (casinoType === "promotion") {
        return processEventList?.length === 0
          ? []
          : processEventList
              ?.filter((item) => item?.is_show_carousel == 1)
              ?.filter((item) => item?.copywriting_is_active == 1)
              ?.map((item) => {
                return {
                  label: item?.reward_title || "",
                  uid: item?.uid || "",
                };
              });
      }
      if (gameListByTypeAndSubType) {
        const sub = Object?.keys(gameListByTypeAndSubType?.[casinoType] || {});
        return ["MyFavorite", ...sub?.reverse()];
      }
    },
    [gameListByTypeAndSubType, casinoType, processEventList]
  );

  const gameTypeList = useMemo(() => {
    if (gameType) {
      const combileType = [...gameType];

      return [
        ...combileType.map((item, index) => {
          const { link } = routeMapping(item);
          const current_game_type = routeMapping(item)?.origin;
          const platform_list = Object?.keys(gameList[current_game_type] || {});

          return platform_list?.length == 0 && !exceptGameType(item) ? null : (
            <SplideSlide key={index}>
              <ChakraLink
                as={(props) => <NavLink {...props} />}
                to={`${link}`}
                onClick={async () => {
                  await waitTime(100);
                  dispatch(CloseMenu());
                }}
                _hover={{
                  textDecoration: "none",
                }}
              >
                {(props) => {
                  return (
                    <Box
                      borderRadius={`common`}
                      // bgGradient="linear(to-b,  #141614, #2c2b2e, #141614)"
                      bgGradient={
                        props.isActive
                          ? "linear(to-b, transparent, brand.500 120%)"
                          : "linear(to-b, transparent, brand.500 400%)"
                      }
                      // overflow="hidden"
                      borderBottom={props.isActive ? `3px solid` : ``}
                      borderColor={props.isActive ? `brand.300` : ``}
                    >
                      <Flex
                        justifyContent="center"
                        minW="100px"
                        maxW="100px"
                        height="42.5px"
                        gap="5px"
                        px="5px"
                        alignItems={`center`}
                        borderRadius={`common`}
                        // borderBottom={isActive ? `3px solid` : ``}
                        // borderColor={isActive ? `brand.300` : ``}
                      >
                        <Icon as={menuIconV2(item)?.icon} fontSize={`12px`} />
                        <Text
                          className="text-overflow"
                          fontSize="12px"
                          fontWeight="600"
                        >
                          {i18n(item)}
                        </Text>
                      </Flex>
                    </Box>
                  );
                }}
              </ChakraLink>
            </SplideSlide>
          );
        }),
      ];
    }
  }, [gameType, i18next.language, gameList]);

  return (
    <ChakraBox
      h={`100%`}
      position={isStickyLeft ? "fixed" : "relative"}
      left="0px"
      pb={{ base: "0px" }}
      {...animationProps}
    >
      <Flex
        w={{
          base: "310px",
          md: "100%",
          lg: "100%",
        }}
        minH="100%"
        maxH="100vh"
        bg={menuBg}
        direction={`column`}
        alignItems={`center`}
        gap={{
          base: "10px",
          lg: "25px",
        }}
        pb={{
          base: "0px",
          lg: "20px",
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        overflowY="scroll"
        overflowX="hidden"
        className="no-scrollbar"
        position="relative"
        pt={{
          base: "30px",
          md: "0px",
        }}
      >
        {/* <Box
          mb={{
            base: "0px",
            lg: "0px",
          }}
          minH={{
            base: "0px",
            md: "100px",
          }}
          maxH="100px"
          bg={logoBg}
          w="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            display={{
              base: "none",
              md: "block",
            }}
          >
            <Link to="/">
              <Image
                src={stylesConfig.mainLogo}
                cursor={"pointer"}
                display={{
                  base: "none",
                  md: "block",
                }}
                width={{
                  md: "150px",
                  base: "65px",
                }}
                pointerEvents={"all"}
                alt={COMMON_ALT}
              />
            </Link>
          </Box>
        </Box> */}
        {/* {isLogin ? (
          <>
            <Box
              display={{
                base: "flex",
                lg: "none",
              }}
              w="100%"
              pl="10px"
              gap="10px"
            >
              <ProfileButton
                path="profile/"
                className="bg-base-light-white text-base-dark dark:bg-base-input-gray dark:text-base-white"
              />
              <Flex direction={`column`} justifyContent={`space-between`}>
                <CustomerNameText className="font-medium tracking-tighter text-light-text !text-base" />
                <Balance />
              </Flex>
            </Box>
            <Divider
              display={{
                base: "block",
                lg: "none",
              }}
              my="5px"
              px="10px"
            />
          </>
        ) : (
          <Box
            display={{
              base: "block",
              lg: "none",
            }}
          >
            <ProfileCardNotLogin />
          </Box>
        )} */}

        <Flex w="100%" direction={`column`} gap="12px">
          {/* <Box
            px={{
              base: "10px",
              md: "0px",
            }}
          >
            <Splide
              options={{
                pagination: false,
                arrows: false,
                drag: "free",
                // perPage: 3,
                perMove: 1,
                gap: 1,
                lazyLoad: "nearby",
                fixedWidth: "95px",
                dragMinThreshold: {
                  mouse: 0,
                  touch: 10,
                },
              }}
            >
              {gameTypeList}
            </Splide>
          </Box> */}
          {!isCredit && (
            <Flex px="10px" direction={`column`} gap="3px">
              <Box
                role="group"
                w="100%"
                pl={{
                  base: "20px",
                  md: "35px",
                }}
                py="10px"
                bg="dark.100"
                borderRadius={`8px`}
              >
                <Link
                  to={`/`}
                  onClick={async () => {
                    await waitTime(100);
                    dispatch(CloseMenu());
                  }}
                >
                  <Flex
                    alignItems={`center`}
                    gap="10px"
                    _hover={{
                      color: textColorHover,
                    }}
                    cursor="pointer"
                    mr="20px"
                    _groupHover={{
                      color: textColorHover,
                    }}
                    color={getCurrentPath === "" ? textColorHover : textColor}
                  >
                    <Icon as={RiHomeSmileFill} size="large" fontSize="25px" />
                    <Text
                      as="h5"
                      fontSize="15px"
                      my="0px"
                      fontWeight={`medium`}
                    >
                      {i18n(`home`)}
                    </Text>
                  </Flex>
                </Link>
              </Box>
            </Flex>
          )}
          {window.roulettes_activity == 1 ? (
            <Flex px="10px" direction={`column`} gap="3px">
              <Box
                role="group"
                w="100%"
                pl={{
                  base: "20px",
                  md: "35px",
                }}
                py="10px"
                bg="dark.100"
                borderRadius={`8px`}
              >
                <Link
                  to={"n-roulette"}
                  onClick={async () => {
                    await waitTime(100);
                    dispatch(CloseMenu());
                  }}
                >
                  <Flex
                    alignItems={`center`}
                    gap="10px"
                    _hover={{
                      color: textColorHover,
                    }}
                    cursor="pointer"
                    mr="20px"
                    _groupHover={{
                      color: textColorHover,
                    }}
                    color={textColor}
                  >
                    <Image
                      className="animate-spin"
                      src={rouletteImage}
                      w="25px"
                      h="25px"
                    />
                    <Text
                      as="h5"
                      fontSize="14px"
                      my="0px"
                      fontWeight={`medium`}
                    >
                      {i18n("LuckyRoulette")}
                    </Text>
                  </Flex>
                </Link>
              </Box>
            </Flex>
          ) : null}{" "}
          {window.check_in_activity == 1 ? (
            <Flex px="10px" direction={`column`} gap="3px">
              <Box
                role="group"
                w="100%"
                pl={{
                  base: "20px",
                  md: "35px",
                }}
                py="10px"
                bg="dark.100"
                borderRadius={`8px`}
              >
                <Link
                  to={"checkin"}
                  onClick={async () => {
                    await waitTime(100);
                    dispatch(CloseMenu());
                  }}
                >
                  <Flex
                    alignItems={`center`}
                    gap="10px"
                    _hover={{
                      color: textColorHover,
                    }}
                    cursor="pointer"
                    mr="20px"
                    _groupHover={{
                      color: textColorHover,
                    }}
                    color={textColor}
                  >
                    <Icon as={FaCalendarAlt} size="large" fontSize="18px" />
                    <Text
                      as="h5"
                      fontSize="14px"
                      my="0px"
                      fontWeight={`medium`}
                    >
                      {i18n("check-in")}
                    </Text>
                  </Flex>
                </Link>
              </Box>
            </Flex>
          ) : null}
          <AppWrapper show>
            <Flex px="10px" direction={`column`} gap="3px">
              <Box
                role="group"
                w="100%"
                pl={{
                  base: "20px",
                  md: "35px",
                }}
                py="10px"
                bg="dark.100"
                borderRadius={`8px`}
              >
                <Box as={Link} to={"app"}>
                  <Flex
                    alignItems={`center`}
                    gap="10px"
                    _hover={{
                      color: textColorHover,
                    }}
                    cursor="pointer"
                    mr="20px"
                    _groupHover={{
                      color: textColorHover,
                    }}
                    color={textColor}
                  >
                    <Icon as={FaMobileAlt} size="large" fontSize="18px" />
                    <Text
                      as="h5"
                      fontSize="14px"
                      my="0px"
                      fontWeight={`medium`}
                    >
                      {i18n("download-app")}
                    </Text>
                  </Flex>
                </Box>
              </Box>
            </Flex>
          </AppWrapper>
          {/* <Divider my="10px" px="10px" /> */}
          <Flex px="10px" direction={`column`} gap="3px">
            {Object.keys(gameListByTypeAndSubType || {})
              ?.filter((item) => !item.hidden)
              ?.map((item) => {
                const subMenu = getSubType(item);
                const { link } = routeMapping(item);
                return (
                  <Flex
                    bg="dark.100"
                    borderRadius={`8px`}
                    direction={`column`}
                    // gap="5px"
                  >
                    <Box
                      role="group"
                      w="100%"
                      pl={{
                        base: "20px",
                        md: "35px",
                      }}
                      pr={{
                        base: "20px",
                        lg: "10px",
                      }}
                      py="10px"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      cursor={`pointer`}
                      onClick={() => {
                        dispatch(controlSubMenu(item));
                      }}
                    >
                      <Box
                        onClick={async () => {
                          await navigate(`${link}`);
                          await waitTime(100);
                          dispatch(CloseMenu());
                        }}
                        key={item}
                      >
                        <Flex
                          alignItems={`center`}
                          gap="10px"
                          w="100%"
                          _hover={{
                            color: textColorHover,
                          }}
                          cursor="pointer"
                          mr="20px"
                          _groupHover={{
                            color: textColorHover,
                          }}
                          color={
                            getCurrentPath === link ? textColorHover : textColor
                          }
                        >
                          <Icon
                            as={menuIconV2(item)?.icon}
                            size="large"
                            fontSize="18px"
                          />
                          <Text
                            as="h5"
                            fontSize="14px"
                            my="0px"
                            fontWeight={`medium`}
                          >
                            {i18n(item)}
                          </Text>
                        </Flex>
                      </Box>
                      {subMenu?.length > 0 && (
                        <Icon
                          // onClick={() => {
                          //   dispatch(controlSubMenu(item));
                          // }}
                          cursor={"pointer"}
                          color={textColor}
                          className={`transition duration-300 animate-rotate ${
                            subMenuControl?.[item] ? "rotate-180" : ""
                          }`}
                          as={IoIosArrowDown}
                        />
                      )}
                    </Box>
                    {/* {getCurrentPath === item.link && ( */}
                    {/* {categoryOpen?.[item.link] && ( */}
                    <ChakraBox
                      initial={false}
                      overflow="hidden"
                      animate={
                        subMenuControl?.[item]
                          ? menuSubVariants.open
                          : menuSubVariants.close
                      }
                    >
                      <Grid
                        position="relative"
                        templateColumns="repeat(2, minmax(0, 1fr))"
                        gap="3px"
                        pb="10px"
                        px="10px"
                      >
                        {subMenu?.map((subItem) => {
                          const currentPath = getSubTypePath?.some((path) => {
                            if (item?.text === "PROMOTION") {
                              return path == subItem?.uid;
                            } else {
                              return path == subItem;
                            }
                          });
                          // const link =
                          //   item?.text === "PROMOTION"
                          //     ? `/promotion/detail/${subItem?.uid}`
                          //     : `/${item.link}/category/${"all"}/${subItem}`;
                          return (
                            <GridItem
                              borderRadius={`8px`}
                              px="5px"
                              py="7px"
                              bg="dark.200"
                            >
                              <Box role="group">
                                <Link
                                  to={`${link}/category/all-platform/${subItem}`}
                                  onClick={async () => {
                                    await waitTime(100);
                                    dispatch(CloseMenu());
                                  }}
                                >
                                  <Flex
                                    alignItems={`center`}
                                    gap="5px"
                                    _groupHover={{
                                      color: textColorHover,
                                    }}
                                    color={
                                      currentPath ? textColorHover : textColor
                                    }
                                    display="flex"
                                    justifyContent={`center`}
                                  >
                                    <Icon
                                      as={menuIcon(subItem, "icon")}
                                      fontSize="15px"
                                    />
                                    <Text
                                      className="text-overflow"
                                      as="h5"
                                      my="0px"
                                      fontSize="13px"
                                    >
                                      {item?.text === "PROMOTION"
                                        ? subItem?.label
                                        : i18n(subItem)}
                                    </Text>
                                  </Flex>
                                </Link>
                              </Box>
                            </GridItem>
                          );
                        })}
                      </Grid>
                    </ChakraBox>
                  </Flex>
                );
              })}
          </Flex>
          <Flex px="10px" direction={`column`} gap="3px">
            {otherMenu
              ?.filter((item) => !item.hidden)
              ?.map((item) => {
                return (
                  <Box
                    role="group"
                    w="100%"
                    pl={{
                      base: "20px",
                      md: "35px",
                    }}
                    pr={{
                      base: "20px",
                      lg: "10px",
                    }}
                    py="10px"
                    bg="dark.100"
                    borderRadius={`8px`}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box
                      onClick={async () => {
                        if (item.text === "CUSTOMER SERVICE") {
                          await dispatch(openChat());
                        } else if (item.text === "LANGUAGE") {
                          await dispatch(openLanguagePop());
                        } else {
                          navigate(`/${item.link}`);
                          await waitTime(100);
                          dispatch(CloseMenu());
                        }
                      }}
                      key={item.text}
                    >
                      <Flex
                        alignItems={`center`}
                        gap="10px"
                        w="100%"
                        _hover={{
                          color: textColorHover,
                        }}
                        cursor="pointer"
                        mr="20px"
                        _groupHover={{
                          color: textColorHover,
                        }}
                        color={
                          getCurrentPath === item.link
                            ? textColorHover
                            : textColor
                        }
                      >
                        <Icon
                          as={item.icon}
                          color={textColor}
                          size="large"
                          fontSize="25px"
                        />
                        <Text
                          as="h5"
                          fontSize="15px"
                          my="0px"
                          fontWeight={`medium`}
                        >
                          {i18n(item.text.toLowerCase())}
                        </Text>
                      </Flex>
                    </Box>
                  </Box>
                );
              })}
          </Flex>
        </Flex>
        <Icon
          as={IoMdClose}
          display={{
            base: "block",
            md: "none",
          }}
          position="absolute"
          top="5px"
          right="5px"
          fontSize="3xl"
          mt="0px"
          color="brand.500"
          cursor="pointer"
          onClick={() => dispatch(CloseMenu())}
        />
      </Flex>
    </ChakraBox>
  );
};

export default MenuV2;
