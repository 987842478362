import React, { useEffect, useMemo, useState } from "react";
import {
  BrowserRouter,
  HashRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  redirect,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "../src/styles/App.css";
import "./i18n/i18n";

import ProgressBlock from "./components/ProgressBlock/ProgressBlock";
import {
  common_route,
  routes,
  routes_v2,
  routes_v3,
  rules_route,
} from "./utils/routes";
import OverflowHidden from "./utils/OverflowHidden";
import ShouldHideMenu from "./utils/hideMenu";
import useDeviceType from "./hook/useDeviceType";
import { API_RELOAD, WEB_LAYOUT } from "./constant";
import { BasicApi } from "./api/basicApi";
import CommonHelmet from "./components/Seo/commonHelmet";
import "../src/styles/splide.css";
import DescriptionDashboard from "./pages/Game/gameDescription/descriptionDashboard";
import { ErrorBoundary } from "react-error-boundary";
import WalletSetting from "./components/SignInUp/components/WalletSetting";
import { getEventList } from "./api/getApi";
import ColorModePage from "./pages/Settings/components/colorModePage";
import PageProgress from "./components/ProgressBlock/PageProgress";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import LiveChat from "./components/LiveChat/LiveChat";
import GoogleNotification from "./pages/Singin/components/GoogleNotification";
import axios from "axios";
import ReactGA from "react-ga4";
import StatusModal from "./pages/StatusModal/StatusModal";
import LoadingPage from "./components/Loading/LoadingPage";
import LanguageVersionSelector from "./pages/Settings/components/LanguageVersionSelector";
import Cookies from "js-cookie";
import CustomChat from "./components/LiveChat/CustomChat";
import EventPop from "./pages/EventList/EventPop";
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_KEY,
};

// TagManager.initialize(tagManagerArgs);
ReactGA.initialize(process.env.REACT_APP_GA_KEY);

function App() {
  const { i18n } = useTranslation();

  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.isTheme === "dark");

  const navigate = useNavigate();
  const location = useLocation();

  const deviceType = useDeviceType();
  const hideMenu = ShouldHideMenu();

  const { lang } = useParams();

  const locationChanger = useMemo(() => {
    if (location.pathname?.includes("profile")) {
      return false;
    } else {
      return location.key;
    }
  }, [location]);

  useEffect(() => {
    const className = "dark";
    const bodyClasses = document.body.classList;

    isDarkMode ? bodyClasses.add(className) : bodyClasses.remove(className);
  }, [isDarkMode]);

  useEffect(() => {
    BasicApi(dispatch)();
    dispatch({ type: "INITIALIZE_API", navigate: navigate });
  }, []);

  const languagePop = useSelector((state) => state.isLanguagePop);
  const walletSettingPop = useSelector((state) => state.isWalletSettingPop);
  const comissionListPop = useSelector((state) => state.isComissionListPop);
  const colorTab = useSelector((state) => state.isColorMode);
  const isGameDescriptionConfig = useSelector(
    (state) => state.isGameDescriptionPop
  );

  useEffect(() => {
    const fetchDataPeriodically = () => {
      dispatch({ type: "UPDATE_DATA_PERIODICALLY", navigate: navigate });
      // dispatch({ type: "CURRENCY_EXCHANGER" });
    };

    const apiIterval = setInterval(() => {
      fetchDataPeriodically();
    }, API_RELOAD);

    return () => clearInterval(apiIterval);
  }, []);

  const ROUTES = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v3":
        return [...rules_route, ...routes_v3];
      case "v2":
        return [...rules_route, ...routes_v2];
      default:
        return [...rules_route, ...routes];
    }
  }, [routes, WEB_LAYOUT]);

  useEffect(() => {
    //記住代理邀請碼
    const searchParams = new URLSearchParams(location.search);
    const currentInviteParams = searchParams.get("invite");
    if (currentInviteParams) {
      Cookies.set("invite", currentInviteParams, { expires: 1 / 48 });
    }
  }, [location]);

  return (
    <ErrorBoundary fallback={<div>&nbsp;</div>}>
      <GoogleNotification />
      <CommonHelmet />
      <section
        className={`${
          deviceType === "PC"
            ? hideMenu
              ? " w-full"
              : "pl-[320px] w-full"
            : ""
        }`}
      >
        {/* <PageProgress key={locationChanger} /> */}

        <Routes location={location}>
          {ROUTES?.filter((route) => !route.hidden)?.map((route, index) => {
            return (
              <Route
                key={route.path}
                path={`${route.path}`}
                element={route.element}
              >
                {common_route
                  ?.filter((route) => !route.hidden)
                  ?.map((child, index) => {
                    return (
                      <Route
                        key={child.path}
                        exact={child.exact}
                        path={`${child.path}`}
                        element={
                          <OverflowHidden>
                            {child.element}
                            <Outlet key={child.path} />
                          </OverflowHidden>
                        }
                      >
                        {child.children?.map((item) => {
                          return (
                            <Route
                              exact={item.exact}
                              key={item.path}
                              path={`${item.path}`}
                              element={
                                <>
                                  {item.element}
                                  <Outlet />
                                </>
                              }
                            />
                          );
                        })}
                      </Route>
                    );
                  })}
              </Route>
            );
          })}
          <Route path="*" element={<Navigate to={`/slots`} />} />
        </Routes>
      </section>
      <ProgressBlock />
      {colorTab && <ColorModePage />}
      {languagePop && <LanguageVersionSelector />}
      {walletSettingPop && <WalletSetting />}
      {isGameDescriptionConfig?.state === true && <DescriptionDashboard />}
      <LoadingPage />
      {process.env.REACT_APP_LIVECHAT_OPEN == "1" ? <LiveChat /> : null}
      {window.customer_service_url && <CustomChat />}
      <StatusModal />
      <EventPop />
    </ErrorBoundary>
  );
}

export default React.memo(App);
