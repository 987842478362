import { useDispatch, useSelector } from "react-redux";
import useProcessEventList from "../../hook/useProcessEventList";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { DATA2_PATH } from "../../constant";
import i18next from "i18next";
import { languageMapping } from "../../i18n/config";
import useDeviceType from "../../hook/useDeviceType";
import { useTranslation } from "react-i18next";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import {
  closeEventPop,
  openEventPop,
} from "../../redux/action/member/memberAction";
import useCheckLogin from "../../hook/useCheckLogin";
import Cookies from "js-cookie";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const EventPop = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`promotions.${key}`);

  const eventList = useSelector((state) => state.isEventList);
  const eventPopOpen = useSelector((state) => state.isEventPopOpen);
  const dispatch = useDispatch();

  const { isLogin, isLoading } = useCheckLogin();

  const navigate = useNavigate();

  const hideToday = Cookies.get("hideToday");

  const [currentSplideIndex, setCurrentSplideIndex] = useState(1);
  const [checking, setChecking] = useState(hideToday);

  const isMobile = useDeviceType() === "Mobile";

  const bgColor = useColorModeValue("light.200", "dark.100");
  const textColor = useColorModeValue("dark.100", "light.100");

  const processEventList = useProcessEventList({ event_list: eventList });
  const filter_event_list = useMemo(() => {
    return processEventList?.filter(
      (item) => item?.copywriting_is_active == 1 && item?.is_show_pop_up == 1
    );
  }, [processEventList]);

  const handleClosePop = () => {
    dispatch(closeEventPop());
  };

  const event_length = useMemo(() => {
    return filter_event_list?.length;
  }, [filter_event_list]);

  const splideRef = useRef(null);

  const handlePrev = () => {
    splideRef.current.splide.go("<");
  };

  const handleNext = () => {
    splideRef.current.splide.go(">");
  };

  const handleCheck = (e) => {
    const checked = e.target.checked;
    if (checked) {
      Cookies.set("hideToday", true, { expires: 1 });
      setChecking(true);
    } else {
      Cookies.remove("hideToday");
      setChecking(false);
    }
  };

  const handleGoToEvent = (uid) => {
    navigate(`/promotion/detail/${uid}`);
    dispatch(closeEventPop());
  };

  useEffect(() => {
    if (hideToday || event_length === 0) {
      return;
    }
    if (!isLoading) {
      setTimeout(() => {
        dispatch(openEventPop());
      }, 3000);
    }
  }, [isLoading, hideToday, dispatch, event_length]);

  return (
    <>
      <Modal
        isCentered
        variant={`brandPrimary`}
        isOpen={eventPopOpen}
        onClose={handleClosePop}
      >
        <ModalOverlay />

        <ModalContent borderRadius="20px" overflow={"hidden"} p="0px">
          <ModalHeader>{i18n("title")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody p="10px" borderRadius={"10px"}>
            <Box p="15px" borderRadius={"10px"} bg={bgColor}>
              <Splide
                ref={splideRef}
                onMove={(props, currentIndex) => {
                  setCurrentSplideIndex(currentIndex + 1);
                }}
                options={{
                  pagination: false,
                  arrows: false,
                  perMove: 1,
                  perPage: 1,
                  gap: 10,
                  lazyLoad: "nearby",
                  dragMinThreshold: {
                    mouse: 0,
                    touch: 10,
                  },
                }}
              >
                {filter_event_list?.map((item) => {
                  const {
                    uid,
                    reward_title,
                    reward_sub_title,
                    reward_content,
                    start_time,
                    end_time,
                    is_between_time,
                  } = item;
                  return (
                    <SplideSlide>
                      <Box
                        h={{
                          base: "175px",
                          md: "175px",
                        }}
                        w="100%"
                        borderRadius={`common`}
                      >
                        <Image
                          w="100%"
                          h="100%"
                          objectFit={`cover`}
                          // src={GetEventImagePath(uid)}
                          onError={({ currentTarget }) => {
                            if (!currentTarget.src.includes("fallback")) {
                              currentTarget.onerror = null;
                              currentTarget.src = `${DATA2_PATH}/event/${uid}/en_US/${uid}_en_US_${
                                isMobile ? "mobile" : "pc"
                              }_image.png`;
                            }
                          }}
                          borderRadius={"15px"}
                          src={`${DATA2_PATH}/event/${uid}/${languageMapping(
                            i18next.language
                          )}/${uid}_${languageMapping(i18next.language)}_${
                            isMobile ? "mobile" : "pc"
                          }_image.png`}
                        />
                      </Box>
                      <Box
                        as="section"
                        w="100%"
                        borderRadius={`common`}
                        minH="145px"
                        position="relative"
                        my="15px"
                        display="flex"
                        flexDirection={`column`}
                        alignItems={`center`}
                        gap="15px"
                        fontWeight={`medium`}
                        maxH="400px"
                        overflow={`auto`}
                      >
                        {/* //如果有start_date end_date就顯示 沒有就不顯示 */}
                        <Box
                          display="flex"
                          flexDirection={`column`}
                          gap="5px"
                          alignItems={`center`}
                        >
                          <Text
                            as="h4"
                            color={textColor}
                            fontSize="2xl"
                            fontWeight={`semibold`}
                            textAlign={`center`}
                          >
                            {reward_title}
                          </Text>
                          <Text
                            as="h5"
                            color={textColor}
                            fontSize="md"
                            fontWeight={`semibold`}
                          >
                            {reward_sub_title}
                          </Text>
                          <Box
                            display="flex"
                            flexDirection={`column`}
                            gap="5px"
                            fontSize="12px"
                            alignItems={`center`}
                          >
                            <Text as="span" color="brand.500">
                              {start_time} ~ {end_time}
                            </Text>
                          </Box>
                        </Box>
                        <Divider />
                        <Box
                          as="section"
                          dangerouslySetInnerHTML={{ __html: reward_content }}
                          color={textColor}
                          w="100%"
                          py="15px"
                          px="20px"
                          className="p-4 md:p-8 jodit-wysiwyg"
                        ></Box>
                        <Box
                          as="section"
                          dangerouslySetInnerHTML={{ __html: reward_content }}
                          color={textColor}
                          w="100%"
                          py="15px"
                          px="20px"
                          className="p-4 md:p-8 jodit-wysiwyg"
                        ></Box>
                        <Box
                          as="section"
                          dangerouslySetInnerHTML={{ __html: reward_content }}
                          color={textColor}
                          w="100%"
                          py="15px"
                          px="20px"
                          className="p-4 md:p-8 jodit-wysiwyg"
                        ></Box>
                        {is_between_time && (
                          <Flex alignItems={`center`} gap="10px">
                            <Button
                              onClick={() => {
                                handleGoToEvent(uid);
                              }}
                              isLoading={checking}
                              // disabled={checking || currentEventStatus?.disabled}
                              variant={`brandPrimary`}
                              type="button"
                            >
                              {i18n("go_to_event")}
                            </Button>
                          </Flex>
                        )}
                      </Box>
                    </SplideSlide>
                  );
                })}
              </Splide>
            </Box>
          </ModalBody>
          <Flex justifyContent={"center"}>
            <Checkbox onChange={handleCheck} isChecked={checking}>
              {i18n("Hide Today")}
            </Checkbox>
          </Flex>
          <ModalFooter pb="0px" px="0">
            <Flex
              w="100%"
              justifyContent={"space-between"}
              alignItems={"center"}
              gap="10px"
            >
              <Button
                px="40px"
                borderTopRightRadius={"20px"}
                borderBottomRightRadius={"0px"}
                onClick={handlePrev}
                leftIcon={<Icon fontSize="xl" as={MdKeyboardArrowLeft} />}
              >
                PREV
              </Button>
              <Text>
                {currentSplideIndex}/{filter_event_list?.length || 0}
              </Text>
              <Button
                px="50px"
                borderTopLeftRadius={"20px"}
                borderBottomLeftRadius={"0px"}
                onClick={handleNext}
                rightIcon={<Icon fontSize="xl" as={MdKeyboardArrowRight} />}
              >
                NEXT
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EventPop;
