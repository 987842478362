import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeChat } from "../../redux/action/member/memberAction";

const CustomChat = () => {
  const openChatRoom = useSelector((state) => state.isOpenChatRoom);
  const dispatch = useDispatch();

  useEffect(() => {
    if (openChatRoom) {
      if (window.customer_service_url) {
        const newWindow = window.open("", "_blank");

        newWindow.location.href = window.customer_service_url;

        dispatch(closeChat());
      }
    }
  }, [openChatRoom, window.customer_service_url]);
  return null;
};

export default CustomChat;
