import InApp from "detect-inapp";
import React from "react";

const AppWrapper = ({ children, closedAppDownload, show }) => {
  const inapp = new InApp(
    navigator.userAgent || navigator.vendor || window.opera
  );

  return !inapp.isInApp &&
    !closedAppDownload &&
    window?.app_download_open == 1 &&
    show ? (
    <>{children}</>
  ) : null;
};

export default AppWrapper;
