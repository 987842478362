import {
  LiveChatWidget,
  useWidgetIsReady,
  useWidgetState,
} from "@livechat/widget-react";
import { useDispatch, useSelector } from "react-redux";
import { closeChat } from "../../redux/action/member/memberAction";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BACK_DASHBOARD } from "../../constant";
import {
  liveChatFreshToken,
  liveChatGetToken,
  liveChatHasToken,
} from "../../api/getApi";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

//需要先去dashboard根據網域設定白名單

const LiveChat = ({ isGameBox }) => {
  const openChatRoom = useSelector((state) => state.isOpenChatRoom);
  const memberData = useSelector((state) => state.isMemberInfo);
  const dispatch = useDispatch();

  const toast = useToast();

  const { memId, uid, email } = memberData || {};

  const chatTrigger = useRef();

  const widgetState = useWidgetState();
  const isWidgetReady = useWidgetIsReady();
  const navigate = useNavigate();

  useEffect(() => {
    if (chatTrigger.current) {
      if (openChatRoom) {
        // if (uid) {
        //   if (isWidgetReady) {
        //     window.LiveChatWidget.call("maximize");
        //   }
        // } else {
        //   navigate("/signin");
        // }
        if (isWidgetReady) {
          window.LiveChatWidget.call("maximize");
        }
      }
    }
  }, [openChatRoom, isWidgetReady]);

  useEffect(() => {
    // if (uid) {
    if (openChatRoom) {
      if (isWidgetReady) {
        toast.closeAll();
        window.LiveChatWidget.call("maximize");
      } else {
        toast({
          status: "loading",
          title: "Loading Chat Room",
          duration: null,
          isClosable: true,
        });
      }
    }
    // }
  }, [isWidgetReady, openChatRoom]);

  const identityProvider = () => {
    const getToken = async () => {
      return await liveChatGetToken();
    };
    const getFreshToken = async () => {
      return await liveChatFreshToken();
    };

    const hasToken = async () => {
      const response = await liveChatHasToken().then((res) => {
        return res;
      });
      return response.message == "true";
    };

    const invalidate = async () => {
      return Promise.resolve();
    };

    return {
      getToken,
      getFreshToken,
      hasToken: () => hasToken(),
      invalidate,
    };
  };

  return (
    <>
      <div
        data-id="ePJTvplTE7Q"
        className="fixed bottom-[-30px] livechat_button"
      >
        <a
          ref={chatTrigger}
          onClick={(e) => {
            e.preventDefault();
          }}
          href="https://www.livechat.com/?utm_source=chat_button&utm_medium=referral&utm_campaign=lc_17492820"
        >
          chat with us
        </a>
      </div>
      <LiveChatWidget
        onVisibilityChanged={(state) => {
          const { visibility } = state;
          if (visibility === "minimized" || visibility === "hidden") {
            dispatch(closeChat());
          }
        }}
        license={process.env.REACT_APP_LIVECHAT_LICENSE}
        // customerName={memId}
        // customerEmail={email}
        // customIdentityProvider={identityProvider}
        // sessionVariables={{
        //   memId: memId,
        //   uid: uid,
        //   backend_player_search_path: `${BACK_DASHBOARD}detail/player-search/${uid}`,
        // }}
      />
    </>
  );
};

export default LiveChat;
