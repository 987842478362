import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { languageTransfer } from "../../../utils/languageTransfer";
import i18next from "i18next";
import { COMMON_WEB_PATH } from "../../../constant";
import { REPLACE_AIR } from "../../../replace_str_constant";
import { AiOutlineClose } from "react-icons/ai";
import GameCatalog from "../../../components/GameCatalog";
import TabTransfer from "../../PointQuery/tabTransfer";
import { Link } from "react-router-dom";
import { storeGameConfig } from "../../../redux/action/game/gameAction";
import { isEmptyObject } from "../../../utils/isEmptyObject";
import LazyImage from "../../../components/Loading/lazyImage";
import LoadingLine from "../../../components/Loading/LoadingLine";
import { isValidMotionProp, motion, useWillChange } from "framer-motion";
import AnimateWrapper from "../../../components/Animate/animateWrapper";
import useGetNowGame from "../../../hook/useGetNowGame";
import ToggleFavorite from "../../../components/Favorite/toggleFavorite";
import { useTranslation } from "react-i18next";
import { getRandomSlice } from "../../../utils/shuffle";
import {
  openGameDescriptionPop,
  storeTransferWallet,
} from "../../../redux/action/member/memberAction";
import useGameIsHasDescription from "../gameDescription/gameIsHasDescription";
import useDetectedPlayerWalletBalance from "../../../hook/useDetectedPlayerWalletBalance";
import NoticeTransfer from "./noticeTransfer";
import useTransferMode from "../../../hook/useTransferMode";
import { closeAccessTool } from "../../../redux/action/game/gameAction";
import { getTransferWallet } from "../../../api/getApi";
import { flattenWalletObject } from "../../../utils/flattenObject";
import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Text,
  chakra,
  shouldForwardProp,
  useColorModeValue,
} from "@chakra-ui/react";
import RewardList from "../../Rewards/components/rewardList";
import useVipInfo from "../../../hook/useVipInfo";
import NowGame from "./nowGame";
import BonusWalletDetail from "./bonusWalletDetail";
import useCheckLogin from "../../../hook/useCheckLogin";
import CreditPage from "../../../utils/credit/credit_page";
import VipOpenPage from "../../../utils/credit/vip_open_page";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const isCredit = process.env.REACT_APP_CREDIT == 1;

const GameConfirm = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`gamebox.${key}`);
  const i18n_common = (key) => t(`common.${key}`);

  const boxBg = useColorModeValue("light.200", "dark.200");
  const textColor = useColorModeValue("dark.100", "light.100");

  const navigate = useNavigate();
  const location = useLocation();

  const { to, gameUid } = useParams();

  const { isLogin } = useCheckLogin();

  const [showRewards, setShowRewards] = useState(false);
  const iconStyle =
    "min-w-[40px] min-h-[40px] rounded-full flex items-center justify-center";

  const gameConfig = useSelector((state) => state.isGameConfig);
  const handleAllGame = useSelector((state) => state.isHandleAllGame);
  const isTriggerTransferTab = useSelector(
    (state) => state.isTriggerTransferTab
  );
  const dispatch = useDispatch();
  const { topGameList, sortedByPlatformGames, allGames, allGamePlatform } =
    handleAllGame;

  const nowGameConfig = useGetNowGame();
  const willChange = useWillChange();

  useEffect(() => {
    dispatch(storeGameConfig(nowGameConfig));
  }, [nowGameConfig]);

  const { game_platform, gameCode, game_subtype, SubPlatformEnum } = gameConfig;

  const [showBlock, setShowBlock] = useState(false);
  const [openTransfer, setOpenTransfer] = useState(false);
  const [animate, setAnimate] = useState(true);
  const [triggerTransferPop, setTriggerTransferPop] = useState(false);

  const scrollContainerRef = useRef(null);

  const previousIsGameConfirm = useMemo(() => {
    if (location.pathname.includes("game-confirm")) {
      return true;
    }
  }, []);

  const isTransferMode = useTransferMode();

  useEffect(() => {
    if (isTriggerTransferTab) {
      setOpenTransfer(true);
    }
  }, [isTriggerTransferTab]);

  useEffect(() => {
    if (isTransferMode) {
      getTransferWallet().then((data) => {
        dispatch(
          storeTransferWallet(flattenWalletObject(data.transferGameWalletInfo))
        );
      });
    }
  }, [isTransferMode]);

  const gameIsHasDescription = useGameIsHasDescription({ game_platform });

  const getPrevPath = useMemo(() => {
    const path = location.pathname.split("/");
    const currentPathIndex = path.findIndex((item) => item === "game-confirm");

    if (currentPathIndex === -1) return "";
    if (currentPathIndex == 1) {
      return "/";
    } else {
      return path.slice(0, currentPathIndex).join("/");
    }
  }, [location.pathname]);

  useEffect(() => {
    const fixedElement = document.getElementById("fixed-game-confirm");
    const handleScroll = (event) => {
      if (fixedElement.scrollTop > 10) {
        setShowBlock(true);
      } else {
        setShowBlock(false);
      }
    };
    fixedElement?.addEventListener("scroll", handleScroll);

    return () => {
      fixedElement?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [gameConfig]);

  const { current_level } = useVipInfo();

  const topGameRandom = useMemo(() => {
    return getRandomSlice(topGameList);
  }, [gameConfig]);

  const detectedPlayerWalletBalanceBiggestThanZero =
    useDetectedPlayerWalletBalance({ to });

  return (
    <AnimateWrapper //渲染名为 AnimateWrapper 的 React 组件，并把一个名为 animate 的属性传给 AnimateWrapper 组件，根据传递的 animate 属性的值，AnimateWrapper 组件将在内部决定是否渲染其子组件以及是否触发动画效果。
      animate={animate}
    >
      <ChakraBox
        initial={{ scale: 0.5, opacity: 0 }} //组件的初始状态，定义了 scale（缩放比例）和 opacity（不透明度）的初始值。初始时，元素的规模为 0.5，透明度为 0。
        animate={{ scale: 1, opacity: 1 }} //定义了组件在活动状态下的属性值。在活动状态下，元素的规模为 1，透明度为 1，表示元素将被缩放到原始大小并变得完全可见。
        exit={{ scale: 0.5, opacity: 0 }} //定义了组件在退出动画时的属性值。在退出动画时，元素的规模将再次缩小至 0.5，透明度将减小至 0，表示元素将渐变消失。
        transition={{
          duration: 0.3, //动画的持续时间为 0.3 秒，表示从一个状态到另一个状态的过渡将在 0.3 秒内完成。
          ease: [0.43, 0.13, 0.23, 0.96],
        }}
        overflowY="scroll"
        position={{
          base: "fixed",
        }}
        top="0"
        left="0"
        zIndex="999999"
        borderRadius={{
          base: "0px",
        }}
        px="0px"
        bg="#eee"
        w={{
          base: "100%",
        }}
        h={{
          base: "100%",
        }}
        style={{ willChange }}
        ref={scrollContainerRef}
        id="fixed-game-confirm"
        className={`no-scrollbar`}
      >
        {/* {triggerTransferPop && isTransferMode ? (
          <NoticeTransfer
            triggerTransferPop={triggerTransferPop}
            setTriggerTransferPop={setTriggerTransferPop}
          />
        ) : null} */}

        <Box
          as="section"
          position="fixed"
          top="0"
          left="0"
          px="10px"
          w="100%"
          h="60px"
          display={{
            base: "flex",
          }}
          alignItems="center"
          justifyContent="space-between"
          zIndex="1"
          bg={showBlock && boxBg}
          className={`transition duration-300`}
        >
          <Link
            onClick={() => {
              setAnimate(false);
              setTimeout(() => {
                navigate(previousIsGameConfirm ? getPrevPath : -1);
              }, 300);
            }}
            // to={previousIsGameConfirm ? getPrevPath : -1}
            className="p-[4px] cursor-pointer rounded-full bg-light-white flex items-center justify-center"
          >
            <AiOutlineClose className="text-xl text-light-text" />
          </Link>
          <Text
            color={textColor}
            fontSize="xl"
            fontWeight="bold"
            className="text-xl font-bold text-base-light-dark dark:text-base-light-white"
          >
            {showBlock &&
              gameConfig?.[
                `gameName${languageTransfer({ lang: i18next.language })}`
              ]}
          </Text>
          <span>&nbsp;</span>
        </Box>
        <Box as="section" position="relative">
          <LazyImage
            src={`${COMMON_WEB_PATH}/game-icon-v2/en/${game_platform}/${REPLACE_AIR(
              gameCode
            )}.webp`}
            alt={
              gameConfig?.[
                `gameName${languageTransfer({ lang: i18next.language })}`
              ]
            }
            className="w-full h-[292px] object-cover"
          />
          <Image
            src={`${COMMON_WEB_PATH}/game-icon-v2/en/${game_platform}/${REPLACE_AIR(
              gameCode
            )}.webp`}
            alt={`${game_platform}_${gameCode}`}
            position="absolute"
            top="292px"
            w="100%"
            h="292px"
            objectFit={"cover"}
            className="rotate-180"
          />
        </Box>
        <Box
          as="section"
          px="16px"
          pt="16px"
          display="flex"
          flexDirection={`column`}
          gap="5px"
          className="game-confirm-blur"
        >
          <Flex alignItems={`flex-start`} justifyContent={`space-between`}>
            <LoadingLine className={`min-w-[100px] !h-[10px]`}>
              <Text
                as="span"
                fontSize="xl"
                fontWeight={`bold`}
                color={textColor}
              >
                {
                  gameConfig?.[
                    `gameName${languageTransfer({ lang: i18next.language })}`
                  ]
                }
              </Text>
            </LoadingLine>
            <Flex gap="5px" alignItems={`center`} justifyContent={`center`}>
              {isLogin && (
                <CreditPage>
                  <VipOpenPage>
                    <Box
                      onClick={() => {
                        setShowRewards((prevTab) => !prevTab);
                      }}
                      className={`${iconStyle} ${
                        showRewards && "border border-main-color"
                      }`}
                    >
                      <Image
                        src={`${COMMON_WEB_PATH}/vip/vip${current_level}_l.avif`}
                        w="25px"
                        objectFit={`cover`}
                      />
                    </Box>
                  </VipOpenPage>
                </CreditPage>
              )}
              <ToggleFavorite />
            </Flex>
          </Flex>
          <Flex alignItems={`center`} justifyContent={`space-between`}>
            <LoadingLine className={`!min-w-[50px] !h-[10px]`}>
              <Text
                as="span"
                fontWeight={`medium`}
                letterSpacing={`tighter`}
                color={textColor}
              >
                {SubPlatformEnum || game_platform}‧{game_subtype}
              </Text>
            </LoadingLine>

            <Flex
              flexDirection={`column`}
              gap="3px"
              alignItems={`flex-end`}
              fontSize="sm"
            >
              {gameIsHasDescription && (
                <Text
                  as="span"
                  color="brand.500"
                  onClick={() => {
                    dispatch(openGameDescriptionPop({ game: game_platform }));
                  }}
                  className="underline"
                >
                  {`>>`} {i18n_common("ruleIntro")}
                </Text>
              )}
            </Flex>
          </Flex>
          <Text as="span" color="red.500">
            {!detectedPlayerWalletBalanceBiggestThanZero && isTransferMode
              ? `*${i18n("pleaseTransfer")}`
              : null}
          </Text>

          <Box
            as="section"
            display="grid"
            mt="10px"
            gap="10px"
            alignItems={`center`}
          >
            <Button
              variant={`brandPrimary`}
              type="button"
              size="md"
              onClick={() => {
                dispatch(closeAccessTool());
                navigate(
                  `/gamebox/${
                    gameConfig?.[
                      `gameName${languageTransfer({
                        lang: i18next.language,
                      })}`
                    ]
                  }/${to}/${gameUid}`
                );
              }}
            >
              {i18n_common("PLAY")}
            </Button>
            {/* {isTransferMode && (
              <Button
                variant={`brandPrimary`}
                type="button"
                size="md"
                onClick={() => {
                  setOpenTransfer(!openTransfer);
                }}
                bg="green.500"
              >
                {i18n_common("TRANSFER")}
              </Button>
            )} */}
          </Box>
          <BonusWalletDetail />
          {showRewards && (
            <>
              <Divider my="5px" />
              <NowGame />
              <RewardList gameConfirmMode direction={"row"} />
              <Box my="5px" />
            </>
          )}

          {isTransferMode && openTransfer && (
            <Box as="section">
              <Divider />
              <TabTransfer />
            </Box>
          )}
        </Box>
        <Box
          as="section"
          bg={boxBg}
          w="100%"
          position="relative"
          zIndex={"1"}
          pt="20px"
          display="flex"
          flexDirection={`column`}
          gap="10px"
        >
          <GameCatalog
            wrapperClassName={`!my-0`}
            list={topGameRandom}
            mainTitle={`Top`}
            swiperProps={{
              customPerView: "confirm-layout",
            }}
          />
          <GameCatalog
            dontTranslateMainTitle
            wrapperClassName={`!my-0`}
            list={sortedByPlatformGames?.[SubPlatformEnum || game_platform]}
            mainTitle={`${SubPlatformEnum || game_platform}`}
            swiperProps={{
              customPerView: "confirm-layout",
            }}
            directToPlatform={SubPlatformEnum || game_platform}
          />
        </Box>
      </ChakraBox>
    </AnimateWrapper>
  );
};

export default GameConfirm;
